import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { useGeoLocationStorage } from '../useGeoLocationStorage';

const getBooleanFromParams = (param: string) => param === 'true';

const parseSearchParams = (searchParams: URLSearchParams) => {
  const params = Object.fromEntries(searchParams.entries());
  const {
    searchType = '',
    search = '',
    userZip = '',
    category,
    pesKeyword,
    pseudoVerticals = '',
    searchMethod = '',
    searchTerm = '',
    keyword = '',
    medicalSpeciality,
    coverageTypes = '',
    isMixedSuggestions,
    isUserTextSearch,
    previous,
    suggestion,
    sectionType,
    specialtyCategory,
    condition,
    aoeCodes,
    userSearchTerm,
    acceptsEap,
    linkName,
  } = params;

  return {
    searchType,
    search,
    userZip,
    category,
    pesKeyword,
    pseudoVerticals,
    searchMethod,
    searchTerm,
    keyword,
    medicalSpeciality,
    coverageTypes,
    isMixedSuggestions: getBooleanFromParams(isMixedSuggestions),
    isUserTextSearch: getBooleanFromParams(isUserTextSearch),
    previous,
    suggestion,
    sectionType,
    specialtyCategory,
    condition,
    aoeCodes: aoeCodes ? aoeCodes?.split(',') : [],
    userSearchTerm,
    acceptsEap,
    linkName,
  };
};

interface Props {
  onTypeAheadDataLoaded: (variables: any) => void;
}

export const useTypeAheadData = ({ onTypeAheadDataLoaded }: Props) => {
  const [searchParams] = useSearchParams();

  const { typeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: any) => ({
      typeaheadSearchStore: state.typeaheadSearchStore,
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );
  const storedLocation = useGeoLocationStorage();
  const { longitude, latitude } = storedLocation;

  const parsedSearchParams = parseSearchParams(searchParams);
  const { searchType, search, linkName } = parsedSearchParams;
  const newSearch = `${search}-${searchType}-${linkName}-${latitude}-${longitude}`;

  useEffect(() => {
    const {
      searchType = '',
      userZip = '',
      category = '',
      pesKeyword,
      search = '',
      pseudoVerticals = '',
      combinedIncludeSpecialityRollupCodes,
      searchMethod = '',
      searchTerm = '',
      keyword = '',
      medicalSpeciality,
      coverageTypes = '',
      isMixedSuggestions,
      isUserTextSearch,
      previous,
      suggestion,
      sectionType,
      specialtyCategory,
      condition,
      aoeCodes = [],
      typeaheadLinkName,
      userSearchTerm,
      bhProgramFacilityAgg,
      acceptsEap,
      gender,
      virtualCare,
    } = typeaheadSearchStore;

    const data = {
      search,
      searchType,
      combinedIncludeSpecialityRollupCodes,
      typeaheadLinkName,
      userZip,
      category,
      pesKeyword,
      pseudoVerticals,
      searchMethod,
      searchTerm,
      keyword,
      medicalSpeciality,
      coverageTypes,
      isMixedSuggestions,
      isUserTextSearch,
      previous,
      suggestion,
      sectionType,
      specialtyCategory,
      condition,
      aoeCodes,
      userSearchTerm,
      bhProgramFacilityAgg,
      acceptsEap,
      gender,
      virtualCare,
    };
    onTypeAheadDataLoaded(data);
  }, [newSearch]);
};
