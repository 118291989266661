import { useForm } from '@abyss/web/hooks/useForm';
import { useToast } from '@abyss/web/hooks/useToast';
import { Button } from '@abyss/web/ui/Button';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { Flex } from '@abyss/web/ui/Flex';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { TextInput } from '@abyss/web/ui/TextInput';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { emailRegex } from '../../frontends/ProviderSearch/routes/MapView/ShareDrawer/utils';
import { useReportInaccurateProvider } from '../../hooks/useReportInaccurateProvider';
import { getCurrentMember } from '../../utils/user.utils';
import { ContentWrapper } from '../ContentWrapper';
import {
  OTHER_LOCATION_DETAILS,
  OTHER_PROVIDER_DETAILS,
  TELL_US_MORE_MAX_LENGTH,
} from './constants';
import { FeedbackCheckboxGroup } from './FeedbackChecboxGroup';
import {
  DrawerSubtitle,
  EmailInputStyle,
  FeedbackFormContainer,
  StyledDrawer,
  StyledTextInputArea,
} from './index.style';
import { useFeedbackFormOptions } from './useFeedbackFormOptions';

export const SendFeedbackFormDrawer = ({
  isOpen,
  setIsOpen,
  type,
  providerData,
  coverageType,
}) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const { infoOptions, locationInformationOptions } = useFeedbackFormOptions();
  const currentMember = getCurrentMember();

  const properties: any = {
    buttonStyles: {
      backgroundColor: '$primary2',
      border: '1px solid $gray4',
      padding: '$sm $lg $sm $md',
      height: '2.625em',
      fontWeight: '$semibold',
      borderRadius: '24px',
      color: '$gray8',
    },
    drawerSize: 492,
    drawerposition: 'right',
  };

  const form = useForm({
    defaultValues: {
      notifyMe: false,
      providerInformation: [],
      locationInformation: [],
    },
  });

  const handleSuccess = () => {
    const successMessage = form.getValues('notifyMe')
      ? t('FEEDBACK_FORM.SUCCESS_MESSAGE_WITH_EMAIL')
      : t('FEEDBACK_FORM.SUCCESS_MESSAGE_WITHOUT_EMAIL');
    setIsOpen(false);
    form.reset();
    toast.show({
      id: 'feedback-form-success-toast',
      variant: 'success',
      message: successMessage,
      autoClose: 5000,
    });
  };

  const handleError = () => {
    setIsOpen(false);
    form.reset();
    toast.show({
      id: 'feedback-form-error-toast',
      variant: 'error',
      message: t('FEEDBACK_FORM.ERROR_MESSAGE'),
      autoClose: 5000,
    });
  };

  const [, reportInaccurateProvider] = useReportInaccurateProvider({
    handleSuccess,
    handleError,
  });

  const handleCloseDrawer = () => {
    setIsOpen(false);
    form.reset();
  };

  const isSubmitButtonDisabled = () => {
    const allValues = form.getValues() || {};

    const {
      freeForm,
      providerInformation,
      locationInformation,
      otherProviderDetails,
      otherLocationDetails,
    } = allValues;

    const checkBoxesValues = [...providerInformation, ...locationInformation];

    if (!(checkBoxesValues.length !== 0 || freeForm)) {
      return true;
    }

    if (checkBoxesValues.length !== 0) {
      if (
        providerInformation?.includes(OTHER_PROVIDER_DETAILS) &&
        !otherProviderDetails
      ) {
        return true;
      }
      if (
        locationInformation?.includes(OTHER_LOCATION_DETAILS) &&
        !otherLocationDetails
      ) {
        return true;
      }
    }

    return false;
  };

  const onSubmit = (formData) => {
    const editsCheckboxValues = [
      ...infoOptions[type]?.options,
      ...locationInformationOptions?.options,
    ].reduce((acc, item) => {
      if (
        item.value !== OTHER_PROVIDER_DETAILS &&
        item.value !== OTHER_LOCATION_DETAILS
      ) {
        acc[item.value] = [
          ...formData.providerInformation,
          ...formData.locationInformation,
        ].includes(item.value);
      }
      return acc;
    }, {});

    const payload = {
      providerData,
      edits: {
        ...editsCheckboxValues,
        ...(formData?.providerInformation?.includes(OTHER_PROVIDER_DETAILS) && {
          otherProviderDetails: formData?.otherProviderDetails,
        }),
        ...(formData?.locationInformation?.includes(OTHER_LOCATION_DETAILS) && {
          otherLocationDetails: formData?.otherLocationDetails,
        }),
        ...(formData?.freeForm && { freeForm: formData.freeForm }),
      },
      submitter: {
        ...(formData?.notifyMe && { submitterEmail: formData?.submitterEmail }),
        submitterRole: 'member',
      },
      lob: currentMember?.lineOfBusiness,
      coverageType,
    };
    reportInaccurateProvider({ variables: payload });
  };

  return (
    <React.Fragment>
      <StyledDrawer
        data-auto-testid="send-feedback-drawer"
        data-testid="send-feedback-drawer"
        isOpen={isOpen}
        onClose={handleCloseDrawer}
        position={properties.position}
        size={properties.drawerSize}
        title={
          <Heading
            color="$primary"
            data-testid="send-feedback-heading"
            offset={5}
          >
            {t('FEEDBACK_FORM.SEND_FEEDBACK')}
          </Heading>
        }
      >
        <ContentWrapper style={{ paddingTop: '12px' }}>
          <FeedbackFormContainer>
            <DrawerSubtitle data-testid="send-feedback-sub-heading">
              {t('FEEDBACK_FORM.SUBTITLE')}
            </DrawerSubtitle>
            <FormProvider
              data-auto-testid="feedback-form"
              data-testid="feedback-form"
              onSubmit={onSubmit}
              state={form}
            >
              <FeedbackCheckboxGroup
                dataTestId="provider-information"
                model="providerInformation"
                options={infoOptions[type]?.options}
                selectedValues={form.getValues('providerInformation')}
                textAreaProperties={{
                  name: OTHER_PROVIDER_DETAILS,
                  label: t('FEEDBACK_FORM.TELL_US_MORE'),
                  placeholder: t('FEEDBACK_FORM.OTHER_INFORMATION_PLACEHOLDER'),
                  maxLength: TELL_US_MORE_MAX_LENGTH,
                }}
                title={infoOptions[type]?.title}
              />
              <FeedbackCheckboxGroup
                dataTestId="location-information"
                model="locationInformation"
                options={locationInformationOptions?.options}
                selectedValues={form.getValues('locationInformation')}
                textAreaProperties={{
                  name: OTHER_LOCATION_DETAILS,
                  label: t('FEEDBACK_FORM.TELL_US_MORE'),
                  placeholder: t('FEEDBACK_FORM.OTHER_INFORMATION_PLACEHOLDER'),
                  maxLength: TELL_US_MORE_MAX_LENGTH,
                }}
                title={locationInformationOptions?.title}
              />

              <Flex css={{ marginTop: '18px' }}>
                <StyledTextInputArea
                  data-testid="tell-us-more-feedback-form"
                  displayMaxLength
                  label={t('FEEDBACK_FORM.TELL_US_MORE')}
                  maxLength={TELL_US_MORE_MAX_LENGTH}
                  model="freeForm"
                  placeholder={
                    t('FEEDBACK_FORM.TELL_US_MORE_PLACEHOLDER') || ''
                  }
                  rows={3}
                  validators={{
                    maxLength: {
                      value: TELL_US_MORE_MAX_LENGTH,
                      message: t(
                        'FEEDBACK_FORM.TELL_US_MORE_VALIDATION_MESSAGE'
                      ),
                    },
                  }}
                />
              </Flex>

              <Flex css={{ margin: '20px 0' }}>
                <Checkbox
                  css={{ 'abyss-checkbox-label': { color: '$gray6' } }}
                  data-testid="feedback-form-notify-me-checkbox"
                  label={t(`FEEDBACK_FORM.${type}_NOTIFY_ME_TEXT`) || ''}
                  model="notifyMe"
                />
              </Flex>

              {form.getValues('notifyMe') && (
                <TextInput
                  css={EmailInputStyle}
                  data-auto-testid="email-address-for-feedback-input"
                  data-testid="email-address-for-feedback-input"
                  label={t('FEEDBACK_FORM.EMAIL_ADDRESS')}
                  leftAddOn={
                    <IconMaterial
                      color={'$gray6'}
                      icon="email"
                      size={24}
                      variant="outlined"
                    />
                  }
                  model="submitterEmail"
                  placeholder={t('FEEDBACK_FORM.EMAIL_ADDRESS') || ''}
                  validators={{
                    required: true,
                    pattern: {
                      value: emailRegex,
                      message: t('FEEDBACK_FORM.ENTER_EMAIL'),
                    },
                  }}
                />
              )}

              <Flex css={{ margin: '24px 0', gap: '10px' }}>
                <Button
                  data-testid="send-feedback-form-submit-button"
                  disabled={isSubmitButtonDisabled()}
                  type="submit"
                >
                  {t('FEEDBACK_FORM.SEND_FEEDBACK')}
                </Button>
                <Button
                  data-testid="feedback-form-drawer-cancel-button"
                  onClick={handleCloseDrawer}
                  variant="outline"
                >
                  {t('FEEDBACK_FORM.CANCEL')}
                </Button>
              </Flex>
            </FormProvider>
          </FeedbackFormContainer>
        </ContentWrapper>
      </StyledDrawer>
    </React.Fragment>
  );
};
