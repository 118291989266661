import { config } from '@abyss/web/tools/config';

import { useCustomQuery } from '../useCustomQuery';
import GET_TYPEAHEAD_DETAILS from './AutoComplete.graphql';

export const useAutoCompleteQuery = (options) =>
  useCustomQuery(GET_TYPEAHEAD_DETAILS, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    clearCache: [
      {
        key: 'AutoComplete',
        variables: [
          'query',
          'network',
          'latitude',
          'longitude',
          'lang',
          'limit',
          'limitSpl',
          'limitOrg',
          'limitPrn',
          'radius',
        ],
      },
    ],
    accessor: 'autoComplete',
  });
