import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import escapeRegExp from 'lodash/escapeRegExp';

import {
  DDP_Code,
  VIRTUAL_BH_PSEUDO_ROLLUP_CODE,
  ddpPseudoCode,
} from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { returnSuppressFlag } from '../../../../utils/featureSuppress';
import { phoneOnly } from '../../../ConstantsStyles';
import { DisplayCoverageTypes } from './DisplayCoverageTypes';
import {
  isPrimaryActiveSuggestion,
  keywordSearchDropdownIconColor,
} from './KeywordSearchDropdown.styled';

type Props = {
  activeSuggestion: number;
  keywordSearchTerm: string;
  getCoverageType: Array<string>;
  memberDDP: string;
  onClick: any;
  index: number;
  value: any;
  hideDisabledTypeahead: boolean;
};
export const SuggestionItem = ({
  activeSuggestion,
  hideDisabledTypeahead,
  keywordSearchTerm,
  getCoverageType,
  memberDDP,
  onClick,
  value,
  index,
}: Props) => {
  const escapedKeywordSearchTerm = escapeRegExp(keywordSearchTerm);
  const isPhone = useMediaQuery(phoneOnly);
  const disableDDP =
    value?.pseudoVerticals == ddpPseudoCode &&
    !memberDDP?.includes(DDP_Code[value?.suggestion.toLowerCase()]) &&
    memberDDP !== DDP_Code['All'];

  const isVirtualCareUnetRailroadSuppressed = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.VIRTUAL_CARE_UNET_RAILROAD
  );
  const isVirtualCareRollUpCodeSuppressed =
    value?.pseudoVerticals == VIRTUAL_BH_PSEUDO_ROLLUP_CODE &&
    isVirtualCareUnetRailroadSuppressed;

  return (
    // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
    <li
      aria-posinset={index}
      aria-selected={activeSuggestion === index}
      id={
        activeSuggestion === index ? 'primary-search-bar-result-suggestion' : ''
      }
      role="option"
    >
      <Link
        before={
          <IconMaterial color={keywordSearchDropdownIconColor} icon="search" />
        }
        css={
          value?.coverageType.some((coverageCode) =>
            getCoverageType.includes(coverageCode)
          ) &&
          !disableDDP &&
          !isVirtualCareRollUpCodeSuppressed
            ? {
                '&.abyss-link-root':
                  activeSuggestion === index ? isPrimaryActiveSuggestion : '',
              }
            : { pointerEvents: 'none' }
        }
        data-testid="search-input-box-common-suggestion"
        key={value.id}
        onClick={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
          onClick(value);
        }}
        style={{ whiteSpace: isPhone ? '' : 'pre' }}
        tabIndex={-1}
      >
        <div
          style={{
            ...{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            },
          }}
        >
          <Text style={{ marginRight: '8px' }}>
            {value?.suggestion
              ?.split(new RegExp(`(${escapedKeywordSearchTerm})`, 'gi'))
              .map((part) => {
                if (part.toLowerCase() === keywordSearchTerm.toLowerCase()) {
                  return (
                    <Text
                      color={
                        !value?.coverageType.some((coverageCode) =>
                          getCoverageType.includes(coverageCode)
                        ) ||
                        disableDDP ||
                        isVirtualCareRollUpCodeSuppressed
                          ? '$gray6'
                          : '$interactive1'
                      }
                      size="18px"
                    >
                      {part}
                    </Text>
                  );
                }
                return (
                  <Text
                    color={
                      !value?.coverageType.some((coverageCode) =>
                        getCoverageType.includes(coverageCode)
                      ) ||
                      disableDDP ||
                      isVirtualCareRollUpCodeSuppressed
                        ? '$gray6'
                        : '$interactive1'
                    }
                    fontWeight="bold"
                    size="18px"
                  >
                    {part}
                  </Text>
                );
              })}
          </Text>
          <DisplayCoverageTypes
            getCoverageType={getCoverageType}
            hideDisabledTypeahead={hideDisabledTypeahead}
            isVirtualCareRollUpCodeSuppressed={
              isVirtualCareRollUpCodeSuppressed
            }
            item={value}
            memberDDP={memberDDP}
          />
        </div>
      </Link>
    </li>
  );
};
