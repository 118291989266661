import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNotAvailableUnknownText } from '../../../../hooks/useNotAvailableUnknownText';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import {
  FacilityDetails,
  FacilityLocationsResponse,
} from '../../../../models/FacilityDetails';
import {
  ProviderDetails,
  ProviderLocation,
  ProviderType,
} from '../../../../models/ProviderDetails';
import {
  eveningAndWeekendSubtext,
  getInNetworkStartDate,
  getPracticeLimitations,
  getServicesText,
  getYesNoText,
} from '../../../../utils/providerDetails.utils';
import { mobileOnly, phoneOnly } from '../../../ConstantsStyles';
import { CopyToClipBoard } from '../CopyToClipBoard';
import { InfoProviderContent } from '../InfoProviderContent';
import {
  infoContainer,
  infoProviderContentContainer,
} from '../ProviderLocationsDetails.style';
import { EmailAndWebsiteInfo } from './EmailAndWebsiteInfo';
import { ListItem } from './ListItem';

export type Props = {
  providerDetails: ProviderDetails | FacilityDetails;
  selectedLocation: ProviderLocation | FacilityLocationsResponse;
};

export const VisionCareAllOtherInfosContainer = ({
  providerDetails,
  selectedLocation,
}: Props) => {
  const { t } = useTranslation();
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const mobileScreen = useMediaQuery(mobileOnly) || isWidget;
  const phoneScreen = useMediaQuery(phoneOnly) || isWidget;

  const { npi = '', providerId = '', providerType } = providerDetails;
  const {
    accessibility,
    isSedationOffered,
    languageLineAvailable,
    languagesSpokenByOffice,
    languagesSpokenByInterpreters,
    medicalInterpreterInOffice,
    methodsOfService,
    network,
    practiceLimitations,
    publicTransportationMethods,
    specialties,
  } = selectedLocation;

  const publicTransportationMap =
    publicTransportationMethods?.map((element) => t(element)) || [];

  const practiceLimitation = getPracticeLimitations(
    practiceLimitations?.age,
    practiceLimitations?.gender
  );
  const inNetworkStartDate = getInNetworkStartDate(network?.start);

  const {
    providerLocations: notAvailableOrUnknownText,
    isNotAvailableUnknownTextEnabled,
  } = useNotAvailableUnknownText();

  return (
    <React.Fragment>
      <Flex
        css={infoProviderContentContainer(mobileScreen)}
        data-testid="vision-care-all-other-infos"
      >
        <EmailAndWebsiteInfo selectedLocation={selectedLocation} />
        {providerType === ProviderType.ORGANIZATION ? (
          <InfoProviderContent
            icon="inventory"
            infoSubtext={specialties?.join(', ')}
            infoTitle={t('VIRTUAL_GROUP_PROVIDER_ABOUT.SPECIALITY')}
            variant="outlined"
          />
        ) : null}
        <InfoProviderContent
          icon="date_range"
          infoSubtext={eveningAndWeekendSubtext(t, selectedLocation)}
          infoTitle={t('OTHER_CARE_DETAILS.EVENING_AND_WEEKEND_OPTIONS')}
        />
        <InfoProviderContent
          icon="accessibility"
          infoSubtext={accessibility?.join(', ')}
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.ACCESSIBILITY')}
        />
        <InfoProviderContent
          icon="translate"
          infoTitle={t('OTHER_CARE_DETAILS.LANGUAGE_SUPPORT')}
          variant="outlined"
        >
          <ListItem
            text={t('OTHER_CARE_DETAILS.LANGUAGES_SPOKEN_BY_STAFF')}
            value={
              languagesSpokenByOffice?.join(', ') || notAvailableOrUnknownText
            }
          />
          <ListItem
            text={t('OTHER_CARE_DETAILS.LANGUAGE_LINE_AVAILABLE')}
            value={getYesNoText(t, languageLineAvailable)}
          />
          <ListItem
            text={t('OTHER_CARE_DETAILS.MEDICAL_INTERPRETER_IN_OFFICE')}
            value={getYesNoText(t, medicalInterpreterInOffice)}
          />
          <ListItem
            text={t(
              'PROVIDER_LOCATIONS_DETAILS.LANGUAGES_SPOKEN_BY_INTERPRETER'
            )}
            value={
              languagesSpokenByInterpreters?.join(', ') ||
              notAvailableOrUnknownText
            }
          />
        </InfoProviderContent>
        <InfoProviderContent
          icon="task"
          infoSubtext={getServicesText(t, selectedLocation)}
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.SERVICES_PERFORMED')}
          variant="outlined"
        />
        <InfoProviderContent
          icon="mobile_friendly"
          infoSubtext={methodsOfService?.join(', ')}
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.METHODS_OF_SERVICE')}
          variant="outlined"
        />
        <InfoProviderContent
          icon="medical_services"
          infoSubtext={`${isSedationOffered ? t('Yes') : t('No')}`}
          infoTitle={t('PROVIDER_LOCATIONS_DETAILS.SEDATION_OFFERED')}
          variant="outlined"
        />

        <InfoProviderContent
          icon="directions_bus"
          infoSubtext={publicTransportationMap.join(', ')}
          infoTitle={t(
            'OTHER_CARE_DETAILS.PUBLIC_TRANSPORTATION_OPTIONS_AVAILABLE'
          )}
        />
        {providerType === ProviderType.PRACTITIONER ? (
          <InfoProviderContent
            icon="pan_tool"
            infoSubtext={
              practiceLimitation && practiceLimitation?.trim().length > 0
                ? practiceLimitation
                : notAvailableOrUnknownText
            }
            infoTitle={t('PROVIDER_LOCATIONS_DETAILS.PRACTICE_LIMITATIONS')}
          />
        ) : null}
        {providerType === ProviderType.ORGANIZATION ? (
          <React.Fragment>
            <InfoProviderContent
              icon="event"
              infoSubtext={inNetworkStartDate}
              infoTitle={t('PROVIDER_LOCATIONS_DETAILS.IN_NETWORK')}
              variant="outlined"
            />
            <InfoProviderContent
              icon="location_city"
              infoSubtext={providerId}
              infoTitle={t('PROVIDER_LOCATIONS_DETAILS.FACILITY_ID')}
              variant="outlined"
            >
              <Flex css={{ gap: '$xs' }}>
                <Text
                  color="$info1"
                  css={infoContainer(phoneScreen)}
                  fontWeight="$bold"
                >
                  {providerId}
                </Text>

                <CopyToClipBoard
                  gap="0"
                  isNotAvailableUnknownTextEnabled={
                    isNotAvailableUnknownTextEnabled
                  }
                  label={t('OTHER_CARE_DETAILS.COPY_FACILITY_ID_TO_CLIPBOARD')}
                  text={providerId}
                  title="facility-id"
                />
              </Flex>
            </InfoProviderContent>
            <InfoProviderContent
              icon="person_pin"
              infoSubtext={npi}
              infoTitle={t('OTHER_CARE_DETAILS.NATIONAL_PROVIDER_IDENTIFIER')}
              variant="outlined"
            >
              <Flex css={{ gap: '$xs' }}>
                <Text
                  color="$info1"
                  css={infoContainer(phoneScreen)}
                  fontWeight="$bold"
                >
                  {npi}
                </Text>

                <CopyToClipBoard
                  gap="0"
                  isNotAvailableUnknownTextEnabled={
                    isNotAvailableUnknownTextEnabled
                  }
                  label={t(
                    'OTHER_CARE_DETAILS.COPY_NATIONAL_PROVIDER_IDENTIFIER_TO_CLIPBOARD'
                  )}
                  text={npi}
                  title="facility-id"
                />
              </Flex>
            </InfoProviderContent>
          </React.Fragment>
        ) : null}
      </Flex>
    </React.Fragment>
  );
};
