import { Brandmark } from '@abyss/web/ui/Brandmark';
import { Flex } from '@abyss/web/ui/Flex';
import { Print } from '@abyss/web/ui/Print';
import { format } from 'date-fns';
import find from 'lodash/find';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { CareCategories, Constants } from '../../../../../common/Constants';
import { ConstantsLagoon } from '../../../../../common/ConstantsLagoon';
import { PremiumCareDisclaimer } from '../../../../../common/DisclaimersContainer/PremiumCareDisclaimer';
import {
  isCnsMember,
  isMnrMember,
} from '../../../../../common/Utils/memberUtils/memberUtils';
import { PortalContext } from '../../../../../context/PortalContext';
import { useLagoon } from '../../../../../hooks/useLagoon';
import { useChipStore } from '../../../../../store/useChipStore';
import { ChipState } from '../../../../../store/useChipStore/chipStore';
import { useTypeaheadStore } from '../../../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../../../store/useTypeaheadStore/typeaheadStore';
import { DateTime } from '../../../../../utils/DateTime';
import { returnInclusionFlag } from '../../../../../utils/featureSuppress';
import { getCurrentMember } from '../../../../../utils/user.utils';
import {
  PrintViewDivider,
  PrintViewProviderBoldText,
  PrintViewProviderMediumText,
  PrintViewProviderNormalText,
} from './PrintView.style';
import { ProviderDemographics } from './ProviderDemographics';
import { ProviderDetails } from './ProviderDetails';

type Props = {
  selectedProviderList: {
    numberOfReviews?: string;
    acceptingNewPatients: boolean;
    providerName: string;
    providerId: string;
    primaryDegrees: string[];
    speciality: string;
    organizationType: string;
    providerType?: string;
    networkStatus: string;
    distance: string;
    isPreferredLab?: boolean;
    isFreeStandingFacility?: boolean;
    ddpCode?: string;
    healthGradeRating?: string;
    isTieredProvider: boolean;
    isTier2Provider: boolean;
    virtualCareOffered?: boolean;
    languagesSpoken: string[];
    website: string;
    address: {
      postalCode: string;
      district: string;
      line: string[];
      state: string;
      city: string;
    };
    firstName?: string;
    middleName?: string;
    lastName?: string;
    costIndicator?: string;
    phones: {
      phone: string[];
    };
  }[];
};

export const getStarsRating = (providerHealthGradeRating: number) => {
  let stars = '';
  const count = Array.from([1, 2, 3, 4, 5]);
  for (let i = 0; i < count.length; i++) {
    if (count[i] <= providerHealthGradeRating) {
      stars += '★';
    } else {
      stars += '☆';
    }
  }
  return stars;
};

export const PrintView = ({ selectedProviderList }: Props) => {
  const { search, sectionType } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      search: state.typeaheadSearchStore.search,
      sectionType: state.typeaheadSearchStore.sectionType,
    }))
  );
  const { chipValue } = useChipStore(
    useShallow((state: ChipState) => ({
      chipValue: state.chipValue,
    }))
  );

  const { t } = useTranslation();
  const pageHeader = `${t(`${sectionType}-results-for`)} ${search || ''}`;
  const theDate = () => {
    const rawDate = format(DateTime.getCurrentDateTime(), 'MMMM dd, yyyy');
    const dateSplit = rawDate.split(' ');
    const parsedTranslatedMonth = t(dateSplit[0]);
    return ` ${parsedTranslatedMonth} ${dateSplit[1]} ${dateSplit[2]}`;
  };

  const uiMessaging = useLagoon(Constants.LAGOON_TABLE.UI_MESSAGING)();

  const disclaimerInPage = find(uiMessaging, {
    key: ConstantsLagoon.HEALTHGRADES.DISCLAIMER_IN_PAGE,
  });

  const healthGradesDisclaimer = find(uiMessaging, {
    key: ConstantsLagoon.HEALTHGRADES.PRINT_VIEW_CONTENT,
  });

  const cnsDisclaimerForNotInclusionPolicyIds = find(uiMessaging, {
    key: ConstantsLagoon.HEALTHGRADES.CNS_DISCLAIMER_FOR_NOT_INCLUSION_STATES,
  });

  const dentalMnrDisclaimer = find(uiMessaging, {
    key: ConstantsLagoon.MNR_DISCLAIMER.DENTAL_MNR_DISCLAIMER,
  });

  const medicalFacilityMnrDisclaimer = find(uiMessaging, {
    key: ConstantsLagoon.MNR_DISCLAIMER.MEDICAL_FACILITY_MNR_DISCLAIMER,
  });

  const visionMnrDisclaimer = find(uiMessaging, {
    key: ConstantsLagoon.MNR_DISCLAIMER.VISION_MNR_DISCLAIMER,
  });

  const directoryMnrDisclaimer = find(uiMessaging, {
    key: ConstantsLagoon.MNR_DISCLAIMER.DIRECTORY_MNR_DISCLAIMER,
  });

  const visionDirectoryDisclaimer = find(uiMessaging, {
    key: ConstantsLagoon.VISION_DICTIONARY.DISCLAIMER_CONTENT,
  });

  const visionDirectoryDisclaimerAddlContent = find(uiMessaging, {
    key: ConstantsLagoon.VISION_DICTIONARY.DISCLAIMER_ADDL_CONTENT,
  });
  const currentMember = getCurrentMember();

  const lob = currentMember?.lineOfBusiness;
  const isMNR = isMnrMember(lob);
  const isCNS = isCnsMember(lob);
  const includeDocAsap =
    isCNS &&
    returnInclusionFlag(
      ConstantsLagoon.FEATURE_INCLUSION_FLAGS.INCLUDE_DOC_ASAP
    );

  const renderDisclaimer = () => {
    if (isMNR) {
      switch (chipValue) {
        case CareCategories.DENTAL:
          return dentalMnrDisclaimer?.message.replace(/\n/g, '<br/>');
        case CareCategories.MEDICAL_SPECIALISTS:
        case CareCategories.FACILITIES:
          return medicalFacilityMnrDisclaimer?.message.replace(/\n/g, '<br/>');
        case CareCategories.ALL:
          return directoryMnrDisclaimer?.message.replace(/\n/g, '<br/>');
        case CareCategories.VISION:
          return visionMnrDisclaimer?.message.replace(/\n/g, '<br/>');
        default:
          return '';
      }
    }

    if (isCNS && !includeDocAsap) {
      return cnsDisclaimerForNotInclusionPolicyIds.message;
    }

    return chipValue === Constants.CHIPS_CATEGORIES.VISION
      ? `${disclaimerInPage?.message.replace(/\n/g, '<br/>')}
                <br><br/>
                ${visionDirectoryDisclaimer?.message ?? ''}
                <br><br/>
                ${visionDirectoryDisclaimerAddlContent?.message ?? ''}`
      : healthGradesDisclaimer?.message;
  };

  const { portalData } = useContext(PortalContext);
  const themeOverride = portalData?.portalConfig;

  const brandConfig = {
    affiliate: themeOverride ? 'optum' : 'uhc',
    name: themeOverride ? 'optum' : 'uhc',
    color: themeOverride ? 'orange' : 'blue',
  };

  return (
    <Print.Section
      css={{
        border: 'none',
        padding: '30px 30px 30px 30px',
        fontFamily: '$primary',
      }}
    >
      <Flex alignContent="flex-start" direction="column">
        <Flex justify="space-between">
          <Brandmark
            affiliate={brandConfig?.affiliate}
            brand={brandConfig?.name}
            color={brandConfig?.color}
            css={{
              'abyss-brandmark': {
                width: '139px',
                height: '43px',
              },
            }}
            size="$lg"
            variant="lockup"
          />
        </Flex>

        <PrintViewDivider orientation="horizontal" />
        <Flex justify="space-between">
          <Flex>
            <PrintViewProviderMediumText color="#000000">
              {pageHeader}
            </PrintViewProviderMediumText>
          </Flex>
          <Flex>
            <PrintViewProviderBoldText color="#000000">
              {t('SHARE_PDF.Date')}:&nbsp;
            </PrintViewProviderBoldText>
            <PrintViewProviderNormalText color="#000000">
              {theDate()}
            </PrintViewProviderNormalText>
          </Flex>
        </Flex>
        <PrintViewDivider orientation="horizontal" />
        {selectedProviderList?.map((provider, index) => (
          <React.Fragment key={provider.providerId}>
            {/** ***** First detail row (Number label, Provider Name, Network Status) ****** */}
            <Flex
              css={{
                'abyss-flex-root': {
                  gap: '4px',
                  width: '100%',
                },
              }}
            >
              <span
                style={{
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                  background: 'black',
                  fontSize: '14px',
                  lineHeight: '16.71px',
                  border: '2px solid #666',
                  color: 'white',
                  textAlign: 'center',
                  fontWeight: '500',
                }}
              >
                {index + 1}
              </span>
              <Flex
                css={{
                  'abyss-flex-root': {
                    gap: '2px',
                    padding: 0,
                    flexWrap: 'nowrap',
                    width: '93%',
                  },
                }}
                direction="column"
              >
                <Flex justify="space-between">
                  {/* left side information */}
                  <ProviderDemographics provider={provider} />
                  {/* right side information */}
                  <ProviderDetails provider={provider} />
                </Flex>
                <PrintViewDivider orientation="horizontal" />
              </Flex>
            </Flex>
          </React.Fragment>
        ))}
      </Flex>
      <Flex
        alignContent="center"
        css={{ marginLeft: '25px', display: 'flex', width: '95%' }}
      >
        <Flex
          alignContent="flex-start"
          css={{
            display: 'flex',
            color: '#333',
            fontFamily: '$primary',
            fontSize: '9px',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
          }}
          direction="column"
        >
          {/* Lagoon data disclaimer */}
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: renderDisclaimer(),
            }}
          />
          <PremiumCareDisclaimer
            isPDFView={true}
            showPremiumCareDisclaimer={true}
          />
        </Flex>
      </Flex>
    </Print.Section>
  );
};
