import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { storage } from '@abyss/web/tools/storage';

import {
  Constants,
  FACILITIES_HOSPITALS_ONLY,
  FilterKeysAndParamsMapping,
  IngressSource,
  LOB,
  NetworkStatus,
  ProviderTypes,
} from '../common/Constants';
import { ConstantsLagoon } from '../common/ConstantsLagoon';
import { ConstantsRoutes } from '../common/ConstantsRoutes';
import {
  fiveRecentSearchCardsScreen,
  fourRecentSearchCardsScreen,
  reviewsFourCardsCarouselScreen,
  reviewsThreeCardsCarouselScreen,
  reviewsTwoCardsCarouselScreen,
  threeRecentSearchCardsScreen,
} from '../common/ConstantsStyles';
import { getDetailsRoute } from '../common/DataCard/DataCardUtils';
import { MockProviderGroupResults } from '../common/MockResults';
import { convertObjectToUrlParams } from '../common/Utils';
import { dateFormatCurrent } from '../common/Utils/datesUtils/formatDate';
import {
  isCnsMember,
  isIfpMember,
} from '../common/Utils/memberUtils/memberUtils';
import { FacilityDetails } from '../models/FacilityDetails';
import { CostCareCategory } from '../models/Lagoon';
import { FacilityDDPCode } from '../models/Provider';
import {
  ProviderDetails,
  ProviderLocation,
  ProviderType,
} from '../models/ProviderDetails';
import { LagoonData } from '../utils/common';
import { returnSuppressFlag } from './featureSuppress';
import { capitalizeFirstLetters } from './providerNames.utils';
import { trimSpaces } from './string.utils';
import { getProviderTypeShortName } from './user.utils';

//import { log() } from 'console';
export const enum PREMIUM {
  YES = 'YES',
  NO = 'NO',
  INFO_UNAVAILABLE = 'INFO_UNAVAILABLE',
}

export const enum UserDDPCode {
  L = 'L',
  R = 'R',
  A = 'A',
  LR = 'LR',
  N = 'N',
}

export const isValidOrgTypeCode = (specialitiesTypeCodes) =>
  !!FACILITIES_HOSPITALS_ONLY.find((organizationCode) =>
    specialitiesTypeCodes?.includes(organizationCode)
  );

export const getProviderSpecialtyOrOrgTypeCode = (
  providerType,
  organizationType,
  speciality
) =>
  (providerType === Constants.RESULT_SECTION.ORGANIZATION &&
  organizationType?.length
    ? organizationType[0]
    : speciality) || '';

export const getProviderDetailTabList = (
  boardCertifications,
  providerLocations,
  isVisionCare,
  isDentalCare
) => {
  const excludeQuality =
    (!boardCertifications?.length && !providerLocations?.length) ||
    isVisionCare ||
    isDentalCare;
  return Constants.PROVIDER_DETAILS.TABS_LIST.filter(
    (tab) => !excludeQuality || tab !== 'Quality'
  );
};

// provider detail tab function.  Need to do this to reduce cognitive complexity in sonar//
export const getFacilityDetailTabList = (isVisionCare, organizationCode) => {
  const includePhysicianDirectory = isValidOrgTypeCode(organizationCode);
  const filteredTabs = Constants.FACILITY_DETAILS.TABS_LIST.filter(
    (tab) => includePhysicianDirectory || tab !== 'Physician Directory'
  );
  return filteredTabs.filter((tab) => !isVisionCare || !(tab === 'Quality'));
};

export function shortenZipCode(postalCode) {
  return postalCode?.slice(0, 5);
}

export const eveningAndWeekendSubtext = (t, location) => {
  if (location.hasEveningAppointments && location.hasWeekendAppointment) {
    return t('OTHER_CARE_DETAILS.EVENING_WEEKEND_OPTIONS');
  }
  if (location.hasEveningAppointments) {
    return t('OTHER_CARE_DETAILS.EVENING_APPOINTMENTS');
  }
  if (location.hasWeekendAppointment) {
    return t('OTHER_CARE_DETAILS.WEEKEND_APPOINTMENTS');
  }

  return null;
};

export const getServicesText = (t, location) => {
  let text = location.servicesOffered?.join(', ');
  if (
    location.servicesOffered &&
    location.servicesOffered?.length > 0 &&
    location.electronicPrescribingOffered
  ) {
    text += `, ${t('OTHER_CARE_DETAILS.ELECTRONIC_PRESCRIBING_OFFERED')}`;
  } else if (location.electronicPrescribingOffered) {
    text += t('OTHER_CARE_DETAILS.ELECTRONIC_PRESCRIBING_OFFERED');
  }
  return text;
};

export const getPracticeLimitations = (
  age: string | undefined,
  gender: string | undefined
): string => {
  if (!!age && !!gender) return `${age} (${gender})`;
  if (!!age) return age;
  if (!!gender) return `(${gender})`;
  return '';
};

export const getInNetworkStartDate = (inNetworkStartingDate: string) => {
  if (inNetworkStartingDate) return dateFormatCurrent(inNetworkStartingDate);
  return undefined;
};

export const getYesNoText = (t, flag: boolean | undefined) =>
  flag ? t('Yes') : t('No');

export const getYesNoNAText = (t, flag: boolean | undefined) => {
  if (flag == undefined) return null;
  if (flag) return t('Yes');

  return t('No');
};

export const getSubPCPText = (
  t,
  isPremiumCarePhysician: string | undefined
) => {
  if (isPremiumCarePhysician === PREMIUM.YES) return t('Yes');
  if (isPremiumCarePhysician === PREMIUM.NO) return t('No');
  return null;
};

export const getPremiumCarePhysician = (
  isPremiumCarePhysician: string | undefined
) => isPremiumCarePhysician === PREMIUM.YES;

export const isUserDDPEligible = (ddpCode: UserDDPCode | undefined) =>
  ddpCode
    ? [UserDDPCode.L, UserDDPCode.R, UserDDPCode.A, UserDDPCode.LR].includes(
        ddpCode
      )
    : false;

const isFacilityDDPEligible = (ddpFacility: FacilityDDPCode | undefined) =>
  ddpFacility
    ? [FacilityDDPCode.L, FacilityDDPCode.R, FacilityDDPCode.LR].includes(
        ddpFacility
      )
    : false;

export const displayPreferredFacilityOnCards = (
  showPremiumCare: boolean | undefined,
  showBenefitsBadge: boolean | undefined,
  isDisplayTier1Identifier: boolean | undefined,
  isDisplayTier2Identifier: boolean | undefined
): boolean | undefined =>
  !showPremiumCare &&
  showBenefitsBadge &&
  !isDisplayTier1Identifier &&
  !isDisplayTier2Identifier;

export const isShowPreferredFacilityBadge = (
  suppressFacilityFlags,
  showPreferredFacilityTag: boolean,
  ddpCodeFacility: FacilityDDPCode | undefined,
  ddpCodeUser: UserDDPCode | undefined,
  isPreferredLabNetwork: boolean | undefined,
  isOxPreferredLab: boolean | undefined,
  isOXMember: boolean | undefined,
  isFreeStandingFacility: boolean | undefined,
  oxfordPlnFlag: boolean | undefined
) => {
  const {
    suppressPreferredFacility,
    suppressPreferredFacilityPLN,
    suppressPreferredFacilityFSF,
    suppressPreferredFacilityDDP,
  } = suppressFacilityFlags || {};

  const isMemberAndFacilityDDPEligible =
    isFacilityDDPEligible(ddpCodeFacility) &&
    isUserDDPEligible(ddpCodeUser) &&
    (ddpCodeUser?.includes(UserDDPCode.A) ||
      ddpCodeFacility
        ?.split(', ')
        .some((ddpCode) => ddpCodeUser?.includes(ddpCode)));

  const isOxMemberAndPreferredLab =
    oxfordPlnFlag && isOXMember && isOxPreferredLab;

  return (
    !suppressPreferredFacility &&
    showPreferredFacilityTag &&
    ((!suppressPreferredFacilityPLN &&
      (isPreferredLabNetwork || isOxMemberAndPreferredLab)) ||
      (!suppressPreferredFacilityFSF && isFreeStandingFacility) ||
      (!suppressPreferredFacilityDDP && isMemberAndFacilityDDPEligible))
  );
};

export const getSuppressFacilityFlags = () => {
  const suppressPreferredFacility = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PREFERRED_FACILITY
  );
  const suppressPreferredFacilityPLN = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PLN
  );
  const suppressPreferredFacilityDDP = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.DDP
  );
  const suppressPreferredFacilityFSF = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.FSF
  );

  return {
    suppressPreferredFacility,
    suppressPreferredFacilityPLN,
    suppressPreferredFacilityDDP,
    suppressPreferredFacilityFSF,
  };
};
export const resolveIncludeSpecialityRollupCodes = (
  includeSpecialityRollupCodesFromParams: string | string[]
) => [
  ...new Set(
    Array.isArray(includeSpecialityRollupCodesFromParams)
      ? includeSpecialityRollupCodesFromParams
      : includeSpecialityRollupCodesFromParams?.split(',') || []
  ),
];

export function convertTimeToAmPm(time: string) {
  if (time?.length !== 5 || time[2] !== ':') return time;

  // compare as a string instead of casting to a number for resiliency
  const amTimes = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
  ];
  const pmTimes = [
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
  ];

  if (time.slice(0, 2) === '00' || time.slice(0, 2) === '24')
    return `12:${time.slice(3, 5)} AM`;
  if (time.slice(0, 2) === '12') return time.concat(' PM');
  if (amTimes.includes(time.slice(0, 2))) {
    if (time[0] === '0') return time.slice(1, 5).concat(' AM');
    return time.slice(0, 5).concat(' AM');
  }

  if (pmTimes.includes(time.slice(0, 2))) {
    const convertedHour = amTimes[pmTimes.indexOf(time.slice(0, 2))];
    if (convertedHour[0] === '0')
      return convertedHour[1].concat(time.slice(2, 5).concat(' PM'));
    return convertedHour.concat(time.slice(2, 5).concat(' PM'));
  }
  return time;
}

export const getUncommonElements = (array1, array2) => {
  const differenceInResults: string[] = [];
  array1.forEach((e1) => {
    if (!array2?.includes(e1)) {
      differenceInResults.push(e1);
    }
  });
  return differenceInResults;
};

export const getProviderResults = (
  sectionType,
  practitionerResults,
  useMockData = false
) => {
  let filteredPractitionerResults: any;
  let totalResultCount;
  let searchRadius;

  switch (sectionType) {
    case Constants.RESULT_SECTION.PROVIDER:
      totalResultCount = practitionerResults?.data?.totalCountPractitioners;
      searchRadius = practitionerResults?.data?.practitionerSearchRadius;
      filteredPractitionerResults = useMockData
        ? Constants.MOCK_RESULTS.providers
        : practitionerResults?.data?.providers;
      break;
    case Constants.RESULT_SECTION.FACILITY:
      totalResultCount = practitionerResults?.data?.totalCountOrganizations;
      searchRadius = practitionerResults?.data?.organizationSearchRadius;
      filteredPractitionerResults = useMockData
        ? Constants.MOCK_RESULTS.facilities
        : practitionerResults?.data?.facilities;
      break;
    case Constants.RESULT_SECTION.PROVIDER_GROUPS:
      totalResultCount = practitionerResults?.data?.totalCountMedicalGroup;
      searchRadius = practitionerResults?.data?.medicalGroupSearchRadius;
      filteredPractitionerResults = useMockData
        ? MockProviderGroupResults
        : practitionerResults?.data?.medicalGroup;
      break;
    default:
      totalResultCount = MockProviderGroupResults.length;
      filteredPractitionerResults = MockProviderGroupResults;
      break;
  }

  return {
    error: practitionerResults?.error,
    results: filteredPractitionerResults,
    totalResultsCount: totalResultCount,
    providerSearchRadius: searchRadius,
    isLoading: practitionerResults?.isLoading || practitionerResults?.loading,
  };
};

export const getProviderType = (providerType, sectionType) => {
  switch (sectionType) {
    case Constants.SEARCH_TYPES.PROVIDER:
      return ProviderTypes.PRACTITIONER;
    case Constants.SEARCH_TYPES.PROVIDER_GROUP:
      return ProviderTypes.MEDICAL_GROUP;
    case Constants.SEARCH_TYPES.FACILITY:
      return ProviderTypes.ORGANIZATION;
    default:
      return providerType;
  }
};

export const providerHasLocations = (
  providerDetails: ProviderDetails | FacilityDetails
): boolean => providerDetails?.providerLocations?.length > 1;

export const filterNotEmptyHoursOfOperations = (selectedLocation) =>
  selectedLocation?.hoursOfOperation?.filter(
    (str) => str.closingTime !== '' || str.openingTime !== ''
  );

/**
 * groupingClosingAndOpeningTime Fn
 *
 *
 * @param t -> useTranslation hook
 * @param notEmptyHoursOfOperation -> An array with the hours of operation that are not empty.
 * PES return in some cases for some providers hours of operation empty so we have to deal with it.
 * i.e Monday hours of operation could potentially come empty since practitioner or facility is not open on Mondays
 *
 * @returns a grouped by day object with the hours of operation (opening and closing) time of the particular provider or an empty {} object that we expect in front-end to display " 🕔 Not Available "
 */
export const groupingClosingAndOpeningTime = (
  t,
  notEmptyHoursOfOperation
): {} =>
  notEmptyHoursOfOperation?.reduce((group, p) => {
    const filteredGroup = { ...group };
    if (!filteredGroup[p.openingTime]) filteredGroup[p.openingTime] = {};

    if (!filteredGroup[p.openingTime][p.closingTime])
      filteredGroup[p.openingTime][p.closingTime] = [];
    const translatedDay = t(p.day);
    filteredGroup[p.openingTime][p.closingTime].push(translatedDay);
    return filteredGroup;
  }, {});

export const getLocationId = (tokenData) =>
  tokenData?.locationId ||
  storage.session.get(
    Constants.STORAGE_KEYS.SESSION.SELECTED_FACILITY_LOCATION_ID
  );

export const getProviderId = (providerId) =>
  providerId || storage.session.get('selectedProviderID');

export const getDdpCode = (code: FacilityDDPCode) => {
  const codeData = {
    [FacilityDDPCode.LR]: 'Lab and Imaging',
    [FacilityDDPCode.L]: 'Lab',
    [FacilityDDPCode.R]: 'Imaging',
  };
  return codeData[code];
};

export const getNetworkStatus = (status) =>
  NetworkStatus[status] ||
  Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE;

export const areAllProviderLocationsNonTieredCheck = (locationsDetails) =>
  locationsDetails?.every(
    (locationsDetail) => locationsDetail?.isTieredProvider === false
  );

export const getSelectedLocationIndex = (providerLocations, selectedId) => {
  const selectedIdIndex = providerLocations?.findIndex(
    (location) => selectedId === location.locationId
  );
  if (selectedIdIndex !== undefined && selectedIdIndex !== -1)
    return selectedIdIndex;

  const defaultLocationIndex = providerLocations?.findIndex(
    (location) => location.defaultLocation
  );
  return defaultLocationIndex === undefined || defaultLocationIndex === -1
    ? 0
    : defaultLocationIndex;
};

export const getProviderLocationWithProviderGroup = (providerLocations) => {
  const locations: Array<ProviderLocation> = [];
  providerLocations?.forEach((location) => {
    if (location?.providerGroupsAffiliations) {
      location?.providerGroupsAffiliations?.forEach((providerGroup) => {
        locations.push({
          ...location,
          providerGroupsAffiliations: [providerGroup],
        });
      });
    } else {
      locations.push(location);
    }
  });
  return locations;
};

export const getRecentSearchCarouselDisplayValByLength = (
  reviewCardsCount
): boolean => {
  const threeCardsCarousel = useMediaQuery(threeRecentSearchCardsScreen);
  const fourCardsCarousel = useMediaQuery(fourRecentSearchCardsScreen);
  const fiveCardsCarousel = useMediaQuery(fiveRecentSearchCardsScreen);
  if (reviewCardsCount === 3) {
    return threeCardsCarousel;
  }
  if (reviewCardsCount === 4) {
    return fourCardsCarousel;
  }
  if (reviewCardsCount === 5) {
    return fiveCardsCarousel;
  }

  return reviewCardsCount >= 6;
};

export const getPatientCardLength = (reviewCardsCount, isWidget): boolean => {
  const bFourCardsCarouselDisplay =
    useMediaQuery(reviewsFourCardsCarouselScreen) || isWidget;
  const bThreeCardsCarouselDisplay =
    useMediaQuery(reviewsThreeCardsCarouselScreen) || isWidget;
  const bTwoCardsCarouselDisplay =
    useMediaQuery(reviewsTwoCardsCarouselScreen) || isWidget;
  const l = false;
  if (reviewCardsCount === 2) {
    return bTwoCardsCarouselDisplay;
  }
  if (reviewCardsCount === 3) {
    return bThreeCardsCarouselDisplay;
  }
  if (reviewCardsCount >= 4) {
    return bFourCardsCarouselDisplay;
  }
  return l;
};

export const getProviderPCPId = (
  providerLocations: ProviderLocation[],
  providerId: string = '',
  memberPCPId: string = ''
): string => {
  if (!providerLocations && !providerId) {
    return '';
  }
  const providerPCPId = providerLocations?.[0]?.pcpId;

  if (providerPCPId) {
    const providerIdAndAddressSeqNbr = providerPCPId.split(' ');

    // If split has multiple entries then providerId format is "providerId adSeqNbr"
    if (providerIdAndAddressSeqNbr.length > 1) {
      return parseInt(providerIdAndAddressSeqNbr[0], 10)
        ?.toString()
        ?.padStart(memberPCPId?.length, '0');
    }
  }

  let pcpId = providerId || '';
  const diff = (memberPCPId?.length || 0) - pcpId.length;

  // The provider id from PES and UES has limited leading zero's when compared to the response from OBAPI.
  // In-order for proper comparison of PCP, concat with leading zero's.
  for (let index = 0; index < diff; index++) {
    pcpId = `0${pcpId}`;
  }

  return pcpId;
};

export const getDetailsPathByType = (providerType: string) => {
  switch (ProviderType[providerType]) {
    case ProviderType.MEDICAL_GROUP:
    case ProviderType.PROVIDER_GROUP:
      return ConstantsRoutes.PROVIDER_GROUP_DETAILS.path;
    case ProviderType.ORGANIZATION:
      return ConstantsRoutes.FACILITY_DETAILS.path;
    default:
      return ConstantsRoutes.PROVIDER_DETAILS.path;
  }
};

// eslint-disable-next-line consistent-return
export const getOfferVirtualCareText = (offerVirtualCareText: string) => {
  let replacedOfferVirtualCareSubtext: string = '';
  if (offerVirtualCareText === undefined) {
    return offerVirtualCareText;
  }
  if (offerVirtualCareText.includes('Audio')) {
    replacedOfferVirtualCareSubtext = offerVirtualCareText.replaceAll(
      'Audio',
      'Phone'
    );
    return replacedOfferVirtualCareSubtext;
  }
};

export const getAcceptingNewPatientsStatus = (
  acceptingNewPatients: boolean | null
) => {
  if (acceptingNewPatients) {
    return {
      icon: 'done',
      acceptingPatientInfo:
        'PROVIDER_LOCATIONS_DETAILS.LOCATION_CARD_ACCEPTING_PATIENTS',
    };
  }
  if (acceptingNewPatients === false) {
    return {
      icon: 'close',
      acceptingPatientInfo:
        'PROVIDER_LOCATIONS_DETAILS.LOCATION_CARD_NOT_ACCEPTING_PATIENTS',
    };
  }

  return {
    icon: 'do_disturb_alt',
    acceptingPatientInfo:
      'PROVIDER_LOCATIONS_DETAILS.LOCATION_CARD_NO_INFO_AVAILABLE',
  };
};

export const getCarePathCategoriesSortOrder = (
  practitioner: boolean,
  userLocale: string,
  costCareCategories: CostCareCategory[],
  isMnr: boolean
): string[] => {
  if (practitioner && !isMnr) {
    return costCareCategories
      .filter((costCareCategory) =>
        costCareCategory.locale.startsWith(userLocale)
      )
      .map((costCareCategory) => costCareCategory.DNT_CostCategory);
  }
  return [];
};

export const showTierProviderTagOnHeader = (
  showTierProviderTag: boolean | undefined,
  isTieredProvider: boolean | undefined
) => showTierProviderTag && isTieredProvider;

export const showTier2ProviderTagOnHeader = (
  showTier2Badge: boolean | undefined,
  isIFPTieredPlan: boolean | undefined,
  isTier2Provider: boolean | undefined
) => showTier2Badge && isIFPTieredPlan && isTier2Provider;

export const showNonTierLocationContent = (
  population: string | undefined,
  unetNonTierOneLocationTag: boolean | undefined,
  uspNonTierOneLocationTag: boolean | undefined,
  isTieredProvider: boolean | undefined
) => {
  if (population === 'USP') {
    return uspNonTierOneLocationTag && isTieredProvider;
  }
  return unetNonTierOneLocationTag && isTieredProvider;
};

export const showPremiumFacilityBadgeOnHeader = (
  showTierProviderTag: boolean | undefined,
  isTieredProvider: boolean | undefined,
  showPreferredFacilityBadge: boolean | undefined
) =>
  !showTierProviderTagOnHeader(showTierProviderTag, isTieredProvider) &&
  showPreferredFacilityBadge;

export const getValueFromPseudoRollupCodeTable = (
  lagoonData: LagoonData | undefined,
  keyName: string
) => {
  if (lagoonData?.action === 'filter') {
    return lagoonData?.[keyName];
  }
  return '';
};
export const getVirtualCareValue = (
  lagoonData: LagoonData | undefined,
  keyName: string
) =>
  getValueFromPseudoRollupCodeTable(lagoonData, keyName) ===
  FilterKeysAndParamsMapping.VirtualCare;

export const getEmployeeAssistanceProgramValue = (
  lagoonData: LagoonData | undefined,
  keyName: string
) =>
  getValueFromPseudoRollupCodeTable(lagoonData, keyName) ===
  FilterKeysAndParamsMapping.AcceptsEap;

export const getFormattedAddress = (address, includeProviderGroupNameFF) => {
  const { city, line, state, postalCode } = address;
  const displayCityOrState = includeProviderGroupNameFF ? city : state;

  return `${line?.join(', ')}, ${displayCityOrState}, ${shortenZipCode(
    postalCode
  )}`;
};

export const isDoLookupByPcpId = (
  hasQueried: boolean,
  isLoading: boolean,
  providerId: string,
  source?: IngressSource
) =>
  hasQueried &&
  !isLoading &&
  !providerId &&
  (source === IngressSource.COVERAGE_AND_BENEFITS ||
    source === IngressSource.DASHBOARD);

export const getPcmhValue = (isPcmh: any): string | null => {
  if (isPcmh === true) return 'Yes';
  else if (isPcmh === false) return 'No';
  return null;
};

export const showPcmhAttribute = (
  lob: string,
  isMedicalGroup: boolean,
  isProviderGroup: boolean,
  isPractitioner: boolean
): boolean => {
  if (lob === LOB.ENI) return false;
  return isMedicalGroup || isProviderGroup || isPractitioner;
};

export const isHospitals = (organizationCode, hospitalsOrgCodes) =>
  !!(organizationCode && hospitalsOrgCodes?.includes(organizationCode.join()));

export const getTexasValue = (isTexas?: boolean): string | null => {
  if (isTexas) return 'Yes';
  else if (isTexas === false) return 'No';
  return null;
};
export const isCnsAttributePolicyIdMatched = (
  additionalAttributes,
  coverageType,
  policyNumber,
  providerType,
  isCns,
  attributeName
): boolean => {
  if (!isCns) {
    return false;
  }

  return additionalAttributes?.some(
    ({
      dntAttributeName,
      active,
      providerType: additionalAttributeProviderType,
      policyIds,
      coverage,
    }) =>
      dntAttributeName === attributeName &&
      active &&
      coverage?.includes(coverageType) &&
      additionalAttributeProviderType
        .split(',')
        ?.some(
          (providerTypes) =>
            trimSpaces(providerTypes) ===
            getProviderTypeShortName(providerType.toLowerCase())
        ) &&
      policyIds
        .split(',')
        ?.some((policyId) => policyNumber === trimSpaces(policyId))
  );
};

export const isDisplayTexasStatesHealthProvider = (
  additionalAttributes,
  coverageType,
  policyNumber,
  providerType,
  isCns
): boolean => {
  if (!isCns) {
    return false;
  }
  return additionalAttributes?.some(
    ({
      dntAttributeName,
      active,
      providerType: newProviderType,
      policyIds,
      coverage,
    }) =>
      active &&
      dntAttributeName === 'TEXAS_HEALTHY_STEPS' &&
      coverage === coverageType &&
      newProviderType
        .split(',')
        ?.some(
          (providerTypes) =>
            trimSpaces(providerTypes) === getProviderTypeShortName(providerType)
        ) &&
      policyIds
        .split(',')
        ?.some((policyId) => policyNumber === trimSpaces(policyId))
  );
};
// If it's a CNS user and the providerId has the address seq nbr included, remove it for provider lookups

export const getNormalizedProviderId = (providerId, lob) => {
  if ((isCnsMember(lob) || isIfpMember(lob)) && providerId?.length > 9) {
    return providerId?.slice(0, 9);
  } else {
    return providerId;
  }
};

export const getDetailsNormalizedProviderId = (
  coverageType,
  lob,
  providerId
) => {
  if (coverageType === 'D' || coverageType === 'DS') return providerId;
  return getNormalizedProviderId(providerId, lob);
};

export const showECP = (lob: string, situsState: string): boolean =>
  isCnsMember(lob) && situsState?.toUpperCase() === 'NC';

const raceAndEthnicityDescription = ['Prefer Not To Say', 'Unknown'];

export const getDescription = (item, t) => {
  if (raceAndEthnicityDescription.includes(item.description)) {
    return capitalizeFirstLetters(
      t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE')
    );
  }
  return capitalizeFirstLetters(item.description);
};

export const getRaceDesc = (items, t) => {
  if (items.raceLevel2 && items.raceLevel3) {
    if (
      raceAndEthnicityDescription.includes(items.raceLevel2?.description) ||
      raceAndEthnicityDescription.includes(items.raceLevel3?.description)
    ) {
      return capitalizeFirstLetters(
        t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE')
      );
    }
    return (
      getDescription(items.raceLevel2, t) +
      ', ' +
      getDescription(items.raceLevel3, t)
    );
  } else if (items.raceLevel3) {
    return getDescription(items.raceLevel3, t);
  } else if (items.raceLevel2) {
    return getDescription(items.raceLevel2, t);
  } else if (items.raceLevel1) {
    return getDescription(items.raceLevel1, t);
  } else {
    return capitalizeFirstLetters(
      t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE')
    );
  }
};

export const getEthinicityDesc = (items, t) => {
  if (items.ethnicityLevel2 && items.ethnicityLevel3) {
    if (
      raceAndEthnicityDescription.includes(
        items.ethnicityLevel2?.description
      ) ||
      raceAndEthnicityDescription.includes(items.ethnicityLevel3?.description)
    ) {
      return capitalizeFirstLetters(
        t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE')
      );
    }
    return (
      getDescription(items.ethnicityLevel2, t) +
      ', ' +
      getDescription(items.ethnicityLevel3, t)
    );
  } else if (items.ethnicityLevel3) {
    return getDescription(items.ethnicityLevel3, t);
  } else if (items.ethnicityLevel2) {
    return getDescription(items.ethnicityLevel2, t);
  } else if (items.ethnicityLevel1) {
    return getDescription(items.ethnicityLevel1, t);
  } else {
    return capitalizeFirstLetters(
      t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE')
    );
  }
};

export const getProviderDetailsRouteUrl = (
  chipStore,
  providerId,
  locationId,
  showDirection,
  isTieredDataCard,
  sectionType,
  choosePCP
) => {
  const detailsRoute = getDetailsRoute(sectionType);
  const urlParams = convertObjectToUrlParams(chipStore, {
    chipValue: chipStore.chipValue,
    coverageType: chipStore.coverageType,
    providerId,
    locationId,
    showDirection,
    isTieredDataCard,
    sectionType,
    choosePCP,
  });

  return `${detailsRoute}${urlParams}`;
};

export const mapLanguagesSpokenByOffice = (languagesSpokenByOffice, t) => {
  if (languagesSpokenByOffice?.length > 0 && languagesSpokenByOffice) {
    return languagesSpokenByOffice.join(', ');
  }
  return t('ENGLISH');
};
