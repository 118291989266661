import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { useCoverageType } from '../../../../hooks/useCoverageType';
import { useLagoon } from '../../../../hooks/useLagoon';
import { useNotAvailableUnknownText } from '../../../../hooks/useNotAvailableUnknownText';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { cnsEthnicity, cnsRace } from '../../../../models/ProviderDetails';
import {
  getEthinicityDesc,
  getRaceDesc,
  isCnsAttributePolicyIdMatched,
} from '../../../../utils/providerDetails.utils';
import { capitalizeFirstLetters } from '../../../../utils/providerNames.utils';
import { getCurrentMember, isCNSMember } from '../../../../utils/user.utils';
import {
  ADDITIONAL_ATTRIBUTE_NAMES,
  ReverseCoverageTypesCodes,
} from '../../../Constants';
import { mobileOnly, phoneOnly } from '../../../ConstantsStyles';
import { ContentWrapper } from '../../../ContentWrapper';
import { getFeatureFlag } from '../../../Utils/getFeatureFlag';
import { ContainerForAllSectionsStyled } from '../../ProviderDetailTabs.styled';

const textContainer = (phoneScreen) => ({
  maxWidth: '839px',
  fontWeight: '$medium',
  fontSize: '$lg',
  lineHeight: '24px',
  color: '#4B4D4F ',
  ...(phoneScreen && {
    fontSize: '$md',
    fontWeight: '500',
    lineHeight: '20px',
  }),
});

const subTextStyle = (phoneScreen) => ({
  height: '20px',
  fontWeight: '$normal',
  fontSize: '$md',
  lineHeight: '20px',
  color: '#4B4D4F',
  ...(phoneScreen && {
    fontSize: '14.22px',
  }),
});

const FlexContainer = styled('p', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px',
  width: '100%',
});
const Section = styled('div', {
  width: '193px',
  minHeight: '44px',
  fontSize: '16px',
  color: '#4B4D4F ',
  lineHeight: '20px',
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
  '@screen < $sm': {
    maxWidth: '163px',
  },
});

const SectionHeading = styled('p', {
  fontSize: '16px',
  marginBottom: '0px',
  fontWeight: '$medium',
});
const Dot = styled('div', {
  width: '4px',
  height: '4px',
  background: '#D9D9D9',
  margin: '$sm $xs $xs $xs',
  borderRadius: '50%',
  color: '#4B4D4F',
});
type Props = {
  heading: string;
  content?: string;
  gender: string;
  language: string[] | null;
  ethnicity?: string;
  cnsRace?: cnsRace[];
  cnsEthnicity?: cnsEthnicity[];
  providerType?: string;
};
export const BiographySection = ({
  heading,
  content,
  gender,
  language,
  ethnicity,
  cnsEthnicity,
  cnsRace,
  providerType,
}: Props) => {
  const { t } = useTranslation();
  const coverageType = useCoverageType();
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const phoneScreen = useMediaQuery(phoneOnly) || isWidget;
  const mobileScreen = useMediaQuery(mobileOnly) || isWidget;
  const featureFlags = useLagoon('feature-flags')();
  const { lineOfBusiness, policyNumber } = getCurrentMember();
  const additionalAttributes = useLagoon('additional-attributes')();
  const isCns = isCNSMember(lineOfBusiness);
  const showRaceAndEthnicity = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.RACE_ETHNICITY
  );
  const showCnsEthnicity: boolean = isCnsAttributePolicyIdMatched(
    additionalAttributes,
    coverageType,
    policyNumber,
    providerType,
    isCns,
    ADDITIONAL_ATTRIBUTE_NAMES.ETHNICITY
  );
  const showCnsRace: boolean = isCnsAttributePolicyIdMatched(
    additionalAttributes,
    coverageType,
    policyNumber,
    providerType,
    isCns,
    ADDITIONAL_ATTRIBUTE_NAMES.RACE
  );

  const isBehavioralHealth =
    coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'];

  const notAvailableOrUnknownText = useNotAvailableUnknownText();

  const getEthnicity = () => {
    if (isBehavioralHealth && isCns) {
      return (
        <Section>
          <SectionHeading data-testid="ethnicity-heading">
            {t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.ETHNICITY')}
          </SectionHeading>
          <Text
            css={subTextStyle(phoneScreen)}
            data-auto-testid="ethnicity-text"
            data-testid="ethnicity-text"
          >
            {ethnicity
              ? capitalizeFirstLetters(ethnicity)
              : capitalizeFirstLetters(
                  notAvailableOrUnknownText?.providerDetails
                )}
          </Text>
        </Section>
      );
    }
    return null;
  };
  const getGender = (gender, t, phoneScreen) => (
    <Text
      css={subTextStyle(phoneScreen)}
      data-auto-testid="biography-gender"
      data-testid="biography-gender"
    >
      {gender?.toLowerCase() === 'male'
        ? capitalizeFirstLetters(t('BIOGRAPHY_SECTION.GENDER.MALE'))
        : capitalizeFirstLetters(t('BIOGRAPHY_SECTION.GENDER.FEMALE'))}
    </Text>
  );

  const getLanguage = (language, phoneScreen) => {
    if (language) {
      return (
        <Text
          css={subTextStyle(phoneScreen)}
          data-auto-testid="biography-language"
          data-testid="biography-language"
        >
          {capitalizeFirstLetters(language?.join(', '))}
        </Text>
      );
    }
    return null;
  };
  const getBiograpghyContent = (content, phoneScreen) => (
    <Text
      css={textContainer(phoneScreen)}
      dangerouslySetInnerHTML={{
        __html: content || t('BIOGRAPHY_SECTION.BIOGRAPHY_NOT_AVAILABLE'),
      }}
      data-auto-testid="biography-text"
      data-testid="biography-text"
    />
  );
  return (
    <ContainerForAllSectionsStyled css={{ marginBottom: '8px' }}>
      <ContentWrapper isWidget={isWidget}>
        <Layout.Stack
          alignItems="left"
          css={{ gap: '4px', lineHeight: phoneScreen ? '20px' : '24px' }}
          data-auto-testid="about-tab-biography-section-container"
          data-testid="about-tab-biography-section-container"
        >
          <Heading
            css={
              mobileScreen
                ? {
                    '&.abyss-heading-root': {
                      fontSize: '20.25px',
                      lineHeight: '24px',
                    },
                  }
                : {}
            }
            data-auto-testid="biography-heading"
            data-testid="biography-heading"
            display="h4"
            offset={1}
          >
            {t(heading)}
          </Heading>
          {getBiograpghyContent(content, phoneScreen)}
          {showRaceAndEthnicity && isCns ? (
            <FlexContainer>
              <Section>
                <SectionHeading>
                  {t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.GENDER')}
                </SectionHeading>
                {getGender(gender, t, phoneScreen)}
              </Section>
              {language && (
                <Section>
                  <SectionHeading>
                    {t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.LANGUAGES')}
                  </SectionHeading>
                  {getLanguage(language, phoneScreen)}
                </Section>
              )}
              {showCnsRace ? (
                <Section>
                  <SectionHeading data-testid="race-heading">
                    {t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.RACE')}
                  </SectionHeading>
                  <Text
                    css={subTextStyle(phoneScreen)}
                    data-auto-testid="biography-race"
                    data-testid="biography-race"
                  >
                    {getRaceDesc(cnsRace, t)}
                  </Text>
                </Section>
              ) : null}
              {showCnsEthnicity ? (
                <Section>
                  <SectionHeading data-testid="ethnicity-heading">
                    {t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.ETHNICITY')}
                  </SectionHeading>
                  <Text
                    css={subTextStyle(phoneScreen)}
                    data-auto-testid="biography-ethnicity"
                    data-testid="ethnicity-text"
                  >
                    {getEthinicityDesc(cnsEthnicity, t)}
                  </Text>
                </Section>
              ) : (
                getEthnicity()
              )}
            </FlexContainer>
          ) : (
            <>
              <Flex css={{ flexWrap: 'inherit' }} justify="space-evenly">
                {getGender(gender, t, phoneScreen)}
                <Dot />
                {getLanguage(language, phoneScreen)}
              </Flex>
              {ethnicity && isBehavioralHealth && (
                <Text
                  css={subTextStyle(phoneScreen)}
                  data-auto-testid="ethnicity-text"
                  data-testid="ethnicity-text"
                >
                  {capitalizeFirstLetters(ethnicity)}
                </Text>
              )}
            </>
          )}
        </Layout.Stack>
      </ContentWrapper>
    </ContainerForAllSectionsStyled>
  );
};
