import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { Button } from '@abyss/web/ui/Button';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { PortalContext } from '../../context/PortalContext';
import { useAdobePageTrackEvent } from '../../hooks/adobeHook/useAdobePageTrackEvent';
import { useLagoon } from '../../hooks/useLagoon';
import { StoreKeys } from '../../hooks/useStore/state';
import { useStore } from '../../hooks/useStore/useStore';
import { useAnalyticsStore } from '../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../store/useAnalyticsStore/analyticsStore';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { usePCPStore } from '../../store/usePCPStore';
import { PCPSearchStore } from '../../store/usePCPStore/pcpStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import { adobeImpressionTrackEvent } from '../AdobeTagging/adobeImpressionTrackEvent';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import {
  Constants,
  CoverageTypesCodes,
  ReverseCoverageTypesCodes,
} from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { notMobile } from '../ConstantsStyles';
import { convertObjectToUrlParams, getFeatureFlag } from '../Utils';
import { handleLinkAndModalTrack } from '../Utils/adobeTrackUtils';
import { CategoryChipsDrawer } from './CategoryChipsDrawer';
import {
  CareCategoriesText,
  ChipButtonText,
  ChipCategoryButton,
  ChipContainer,
  ChipMenuIcon,
  MenuList,
  Wrapper,
  WrapperHeightStyle,
  WrapperStyle,
  chipSelectorDrawerMobile,
  chipsCategory,
  drawerHeadingStyle,
  hamburgerButton,
} from './ChipsCategory.styles';

interface ChipObject {
  label: string;
  value: string;
  id: string;
  enabled: boolean;
}
type Props = {
  chips: ChipObject[];
  customMessage?: string;
  isMixedResultsPage?: boolean;
  resultSection?: boolean;
};

const getConditionToShowChipCategories = (
  headerChipEnabled,
  enableMixedResultPageUpdatedDesign,
  resultSection,
  choosePCP
) =>
  headerChipEnabled &&
  enableMixedResultPageUpdatedDesign &&
  resultSection &&
  !choosePCP;

export const ChipsCategory = ({
  chips,
  customMessage,
  isMixedResultsPage = false,
  resultSection = false,
}: Props) => {
  const desktopScreen = useMediaQuery(notMobile);
  const { t } = useTranslation();
  const { navigate } = useRouter();
  const [windowSize, setWindowSize] = useState<number>(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);
  const [shouldScroll, setShouldScroll] = useState<boolean>(false);
  const { portalData } = useContext(PortalContext);
  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const {
    chipValue: contextValue,
    setCoverageType: setStoreCoverageType,
    setChipValue: setStoreChipValue,
  } = chipStore;
  const { setAnalyticsState } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      setAnalyticsState: state.setAnalyticsState,
    }))
  );
  const { choosePCP } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      choosePCP: state.pcpSearchState.choosePCP,
    }))
  );
  const [headerChipEnabled, setHeaderChipEnabled] = useState(true);
  const {
    searchType = '',
    includeSpecialityRollupCodes = '',
    pseudoVerticals,
    providerType = '',
    search,
    isMixedSuggestions,
    isUserTextSearch = false,
  } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      searchType: state.typeaheadSearchStore.searchType,
      includeSpecialityRollupCodes:
        state.typeaheadSearchStore.includeSpecialityRollupCodes,
      pseudoVerticals: state.typeaheadSearchStore.pseudoVerticals,
      suggestion: state.typeaheadSearchStore.suggestion,
      search: state.typeaheadSearchStore.search,
      providerType: state.typeaheadSearchStore.providerType,
      isMixedSuggestions: state.typeaheadSearchStore.isMixedSuggestions,
      isUserTextSearch: state.typeaheadSearchStore.isUserTextSearch,
    }))
  );
  const [careCategory, setCareCategory] = useState(contextValue);
  const containerRef = useRef<HTMLDivElement>(null);
  const menuListRef = useRef<HTMLElement>();
  const chipsRefs = useRef<HTMLDivElement[]>([]);
  chipsRefs.current = [];
  const [displayHamburgerMenuIcon, setDisplayHamburgerMenuIcon] =
    useState(false);
  const loggedInMemberIndex = storage.session.get(
    Constants.STORAGE_KEYS.SESSION.LOGGED_IN_MEMBER_INDEX
  );
  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();
  const enableMixedResultPageUpdatedDesign: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_MIXED_RESULT_PAGE_UPDATED_DESIGN
  );
  const enableSearchResultsV2: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_SEARCH_RESULTS_V2
  );
  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: `${contextValue} overview`,
  });

  const addChipRef = (el) => {
    if (el && !chipsRefs.current.includes(el)) {
      chipsRefs.current.push(el);
    }
  };

  useEffect(() => {
    if (portalData?.portalConfig) {
      setHeaderChipEnabled(!!portalData?.portalConfig?.headerChipsEnabled);
    }
  }, [portalData]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (containerRef.current != null) {
        setWindowSize(containerRef.current.offsetWidth);
      }
    };
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    const updateScrollCategory = () => {
      const scrollCategoryTimeout = setTimeout(() => {
        clearTimeout(scrollCategoryTimeout);

        if (!desktopScreen && windowSize < desktopScreen) {
          const index = chips.findIndex((x) => x.value === contextValue);
          chipsRefs?.current[index]?.scrollIntoView({ inline: 'start' });
        }
      }, 800);
    };
    updateScrollCategory();
    window.addEventListener('resize', updateScrollCategory);
    return () => {
      debounce(
        () => window.removeEventListener('resize', handleWindowResize),
        400
      );
    };
  }, [windowSize]);

  useEffect(() => {
    if (shouldScroll) {
      containerRef?.current?.scrollIntoView({ block: 'center' });
    }
    if (isOpen) {
      menuListRef?.current?.focus();
      handleLinkAndModalTrack(
        Constants.CHIPS_CATEGORIES.TYPES_OF_CARE,
        'sub nav',
        Constants.CHIPS_CATEGORIES.TYPES_OF_CARE
      );
    }

    if (
      contextValue === 'Dental' ||
      contextValue === 'Vision' ||
      contextValue === 'Health Supplies' ||
      contextValue === 'Facilities' ||
      contextValue === 'Behavioral Health'
    ) {
      let chipsLengthValue =
        contextValue === 'Dental' || contextValue === 'Vision' ? 1 : 4;
      if (contextValue === 'Behavioral Health') {
        chipsLengthValue = 5;
      }
      if (!desktopScreen) {
        requestAnimationFrame(() => {
          chipsRefs?.current[chips.length - chipsLengthValue]?.scrollIntoView({
            block: 'nearest',
            inline: 'start',
          });
        });
      }
      containerRef?.current?.scrollIntoView({ block: 'center' });

      setCareCategory(contextValue);
    }
  }, [contextValue, isOpen, menuListRef]);

  useEffect(() => {
    if (containerRef.current != null) {
      setDisplayHamburgerMenuIcon(
        containerRef.current?.offsetWidth < containerRef.current?.scrollWidth
      );
    }
  }, [windowSize]);

  useEffect(() => {
    if (
      loggedInMemberIndex != null &&
      !isMixedResultsPage &&
      contextValue === Constants.CHIPS_CATEGORIES.ALL // Remaining chips page tracks will be based on provider data. Implemented in useAdobeProvidersImpressionBlock hook.
    ) {
      adobePageTrackEvent();
    }
  }, [contextValue, loggedInMemberIndex]);

  useEffect(() => {
    if (customMessage && Constants.CHIPS_CATEGORIES.ALL) {
      adobeImpressionTrackEvent({
        type: 'alert',
        message: customMessage,
      });
    }
  }, [customMessage]);

  const setHasBreadCrumbClickedFlag = useStore(
    StoreKeys.SET_BREADCRUMB_CLICKED_FLAG
  );

  const onChipClicked = (value, index, isResponsiveView?: boolean) => {
    setHasBreadCrumbClickedFlag(true);
    adobeLinkTrackEvent({
      name: isResponsiveView ? 'change button' : value,
      location: isResponsiveView
        ? `modal:${Constants.CHIPS_CATEGORIES.TYPES_OF_CARE}`
        : 'sub-nav',
      type: 'internal',
    });
    if (index !== undefined) {
      chipsRefs?.current[index]?.scrollIntoView({ inline: 'start' });
    }

    if (value === Constants.CHIPS_CATEGORIES.ALL) {
      setStoreChipValue(value);
      setStoreCoverageType('');
      setCareCategory(value);
      if (!isMixedResultsPage) {
        navigate(`/`);
      }
    } else {
      const selectedCoverageType =
        find(Constants.MOCK_CARE_CATEGORIES, {
          value,
        })?.coverageType || CoverageTypesCodes.MEDICAL;

      setStoreChipValue(value);
      setStoreCoverageType(ReverseCoverageTypesCodes[selectedCoverageType]);
      setCareCategory(value);
      setShouldScroll(true);
      setAnalyticsState({ originLinkNameForAnalytics: value });

      const chipStoreValues = {
        chipValue: value,
        coverageType: ReverseCoverageTypesCodes[selectedCoverageType],
      };

      let urlParams = convertObjectToUrlParams(chipStore, {
        ...chipStoreValues,
      });

      if (!isMixedResultsPage) {
        navigate(`/${urlParams}`, { replace: true });
      } else {
        urlParams = convertObjectToUrlParams(chipStore, {
          ...chipStoreValues,
          search,
          searchType,
          providerType,
          includeSpecialityRollupCodes,
          pseudoVerticals,
          isMixedSuggestions,
          isUserTextSearch,
        });
        if (!enableSearchResultsV2) {
          navigate(`/results/${urlParams}`, { replace: true });
        }
      }
    }
  };

  const handleKeyDown = (value, index, e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onChipClicked(value, index);
    }
  };

  return (
    <React.Fragment>
      {!desktopScreen && isOpen && (
        <Drawer
          css={chipSelectorDrawerMobile}
          isOpen={isOpen}
          onClose={() => {
            adobeLinkTrackEvent({
              name: 'close',
              location: `modal:${Constants.CHIPS_CATEGORIES.TYPES_OF_CARE}`,
            });
            setIsOpen(false);
          }}
          position="bottom"
          title={
            <Heading css={drawerHeadingStyle} offset={3}>
              {t(Constants.CHIPS_CATEGORIES.TYPES_OF_CARE)}
            </Heading>
          }
        >
          <CategoryChipsDrawer
            careCategory={careCategory}
            chips={chips}
            onChipClicked={onChipClicked}
            setCareCategory={setCareCategory}
            setIsOpen={setIsOpen}
          />
        </Drawer>
      )}
      {desktopScreen && isOpen && displayHamburgerMenuIcon && (
        <MenuList
          data-auto-testid="category-menu-list"
          data-testid="category-menu-list"
          id="category-menu-list"
          ref={menuListRef}
          tabIndex="0"
        >
          <Flex justify="space-between">
            <CareCategoriesText>
              {t(Constants.CHIPS_CATEGORIES.TYPES_OF_CARE)}
            </CareCategoriesText>
            <Button
              css={{
                paddingTop: '6px',
                paddingBottom: '36px',
                paddingLeft: '8px',
                paddingRight: '0px',
              }}
              data-auto-testid="chips-menu-drawer-close"
              data-testid="chips-menu-drawer-close"
              onClick={() => {
                setIsOpen(false);
              }}
              variant="tertiary"
            >
              <IconMaterial icon="close" />
            </Button>
          </Flex>
          <CategoryChipsDrawer
            careCategory={careCategory}
            chips={chips}
            onChipClicked={onChipClicked}
            setCareCategory={setCareCategory}
            setIsOpen={setIsOpen}
          />
        </MenuList>
      )}
      {headerChipEnabled && !enableMixedResultPageUpdatedDesign && (
        <>
          <Flex
            css={{ display: 'flex', flexWrap: 'nowrap', paddingLeft: '0px' }}
            direction="row"
          >
            <Text
              css={chipsCategory}
              data-testid="results-page-heading"
              id="care-categories"
            >
              {isMixedResultsPage
                ? t('WE_FOUND_RESULTS_IN_THESE_CATEGORIES')
                : t('CARE_CATEGORIES')}
            </Text>
          </Flex>
          <Wrapper css={WrapperStyle} direction="row">
            {displayHamburgerMenuIcon && (
              <ChipMenuIcon>
                <Button
                  aria-label={t('CARE_OPTIONS')}
                  css={hamburgerButton}
                  data-auto-testid="hamburger-icon-chips-header-container"
                  data-testid="hamburger-icon-chips-header-container"
                  onKeyDown={(e) => {
                    if (e.key === ' ' || e.key === 'Enter') {
                      setIsOpen(true);
                    }
                  }}
                  variant="ghost"
                >
                  <IconMaterial
                    color="$primary1"
                    data-auto-testid="bulleted-icon-chips-list"
                    data-testid="bulleted-icon-chips-list"
                    icon="format_list_bulleted"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                    size="24px"
                  />
                </Button>
              </ChipMenuIcon>
            )}
            <ChipContainer
              aria-labelledby="care-categories"
              className="hide-scrollbar"
              data-auto-testid="chip-container"
              data-testid="chip-container"
              ref={containerRef}
              role="group"
            >
              {chips.map((item: ChipObject, index) => (
                <Flex
                  data-auto-testid={`${item.id}-chip`}
                  data-testid={`${item.id}-chip`}
                  key={item.label}
                  value={item.value}
                >
                  <ChipCategoryButton
                    aria-label={t(item.label)}
                    aria-pressed={
                      contextValue === item.value ? 'true' : 'false'
                    }
                    className={contextValue === item.value ? 'active' : ''}
                    data-auto-testid={`chips-category-${item.id}-chip`}
                    data-testid={`chips-category-${item.id}-chip`}
                    disabled={!item.enabled}
                    onClick={(e) => {
                      onChipClicked(item.value, e);
                    }}
                    onKeyDown={(e) => {
                      handleKeyDown(item.value, index, e);
                    }}
                    ref={addChipRef}
                    variant="outline"
                  >
                    <ChipButtonText
                      disabled={!item.enabled}
                      isActive={contextValue === item.value ? true : false}
                    >
                      {t(item.label)}
                    </ChipButtonText>
                  </ChipCategoryButton>
                </Flex>
              ))}
            </ChipContainer>
          </Wrapper>
        </>
      )}
      {getConditionToShowChipCategories(
        headerChipEnabled,
        enableMixedResultPageUpdatedDesign,
        resultSection,
        choosePCP
      ) && (
        <>
          <Wrapper css={WrapperHeightStyle} direction="row">
            {displayHamburgerMenuIcon && (
              <ChipMenuIcon>
                <Button
                  aria-label={t('CARE_OPTIONS')}
                  css={hamburgerButton}
                  data-auto-testid="hamburger-icon-chips-header-container"
                  data-testid="hamburger-icon-chips-header-container"
                  onKeyDown={(e) => {
                    if (e.key === ' ' || e.key === 'Enter') {
                      setIsOpen(true);
                    }
                  }}
                  variant="ghost"
                >
                  <IconMaterial
                    color="$primary1"
                    data-auto-testid="bulleted-icon-chips-list"
                    data-testid="bulleted-icon-chips-list"
                    icon="format_list_bulleted"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                    size="24px"
                  />
                </Button>
              </ChipMenuIcon>
            )}
            <ChipContainer
              aria-labelledby="care-categories"
              className="hide-scrollbar"
              css={{
                '@screen < $md': {
                  gap: '6px !important',
                },
                '@screen >= $md': {
                  gap: '8px !important',
                },
              }}
              data-auto-testid="chip-container"
              data-testid="chip-container"
              ref={containerRef}
              role="group"
            >
              {chips.map((item: ChipObject, index) =>
                // Check if the item is enabled before rendering the chip
                item.enabled ? (
                  <Flex
                    data-auto-testid={`${item.id}-chip`}
                    data-testid={`${item.id}-chip`}
                    key={item.label}
                    value={item.value}
                  >
                    <ChipCategoryButton
                      aria-label={t(item.label)}
                      aria-pressed={
                        contextValue === item.value ? 'true' : 'false'
                      }
                      className={contextValue === item.value ? 'active' : ''}
                      css={{ marginLeft: '0 !important' }}
                      data-auto-testid={`chips-category-${item.id}-chip`}
                      data-testid={`chips-category-${item.id}-chip`}
                      disabled={!item.enabled}
                      onClick={(e) => {
                        onChipClicked(item.value, e);
                      }}
                      onKeyDown={(e) => {
                        handleKeyDown(item.value, index, e);
                      }}
                      ref={addChipRef}
                      variant="outline"
                    >
                      <ChipButtonText
                        disabled={!item.enabled}
                        isActive={contextValue === item.value ? true : false}
                      >
                        {t(item.label)}
                      </ChipButtonText>
                    </ChipCategoryButton>
                  </Flex>
                ) : null
              )}
            </ChipContainer>
          </Wrapper>
        </>
      )}
    </React.Fragment>
  );
};
