import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import React from 'react';

import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { useLagoon } from '../../../../hooks/useLagoon';
import { DateTime } from '../../../../utils/DateTime';
import {
  TimeStampContentContainer,
  TimeStampContentHeadingStyle,
  TimeStampContentSubTitleStyle,
  TimeStampDate,
} from './TimeStampContent.styled';

function getFormattedDate(): string {
  return DateTime.getCurrentDateTime().toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
}

export const TimeStampContent = () => {
  const formattedDate = getFormattedDate();
  const data = useLagoon('landing-info')();

  const timeStampLabel = find(data, {
    Timestamp_key: ConstantsLagoon.LANDING_INFO.TIME_STAMP_CONTENT,
  });

  const timeStampContent = data.filter(
    (item) => item.Timestamp_key !== 'TIME_STAMP_CONTENT'
  );

  return (
    <React.Fragment>
      <TimeStampContentContainer>
        <Text
          data-auto-testid="directory-update-timestamp"
          data-testid="directory-update-timestamp"
        >
          <TimeStampContentHeadingStyle>
            {timeStampLabel?.TimeStamp_Header}
          </TimeStampContentHeadingStyle>
          <TimeStampContentSubTitleStyle>
            {timeStampLabel?.Timestamp_Value}
          </TimeStampContentSubTitleStyle>
        </Text>
        <div
          className="date-info"
          data-auto-testid="directory-last-updated-container"
          data-testid="directory-last-updated-container"
        >
          {timeStampContent.map((item, index) => (
            <TimeStampDate key={index}>
              <span className="header">{item?.TimeStamp_Header}</span>{' '}
              {formattedDate}
            </TimeStampDate>
          ))}
        </div>
      </TimeStampContentContainer>
    </React.Fragment>
  );
};
