import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { getModuleOrDefault } from '@optum-fpc-psx-mobile-apps/mapping-files-translation';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import { adobeImpressionTrackEvent } from '../../../../common/AdobeTagging/adobeImpressionTrackEvent';
import { adobeLinkTrackEvent } from '../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { adobeSearchModifiedEvent } from '../../../../common/AdobeTagging/adobeSearchModifiedEvent';
import { adobeStandardSearchEvent } from '../../../../common/AdobeTagging/adobeStandardSearchEvent';
import {
  CareCategories,
  Constants,
  PseudoRollupCodeTableKeys,
  ReverseCoverageTypesCodes,
  TypeaheadChipCategoriesMapping,
  errorCodeRouteMap,
} from '../../../../common/Constants';
import {
  FeatureFlags,
  SECTION_TERM,
} from '../../../../common/ConstantsFeatureFlags';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { ConstantsRoutes } from '../../../../common/ConstantsRoutes';
import { phoneOnly } from '../../../../common/ConstantsStyles';
import { DisclaimersContainer } from '../../../../common/DisclaimersContainer/DisclaimersContainer';
import { SectionSkeleton } from '../../../../common/LazyLoaderSkeleton/SectionSkeleton';
import { NullResultsPage } from '../../../../common/NullResultsPage';
import { PSXHeader } from '../../../../common/PSXHeader';
import { getCategoryFromChipCategoriesCount } from '../../../../common/RecentActivity/helper';
import { StillNeedHelp } from '../../../../common/StillNeedHelp';
import { convertObjectToUrlParams } from '../../../../common/Utils';
import {
  convertCoverageType,
  getIndicatorsForImpressions,
  getSearchLocation,
  getTier1Indicator,
} from '../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { CountySearchContext } from '../../../../context/CountySearchContext';
import { PortalContext } from '../../../../context/PortalContext';
import { useAdobePageTrackEvent } from '../../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useCoverageType } from '../../../../hooks/useCoverageType';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { useGeoLocationStorage } from '../../../../hooks/useGeoLocationStorage';
import { useInitializeStore } from '../../../../hooks/useInitializeStore';
import { useLagoon } from '../../../../hooks/useLagoon';
import {
  usePlanYear,
  useSaveRecentSearches,
} from '../../../../hooks/useRecentSearches/useRecentSearches';
import { useSpecialtiesResult } from '../../../../hooks/useSpecialties';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { useTypeAheadData } from '../../../../hooks/useTypeAheadData';
import { useTypeaheadPseudoRollup } from '../../../../hooks/useTypeaheadPseudoRollup';
import {
  getTypeaheadPseudoRollup,
  useTypeaheadPseudoRollupTable,
} from '../../../../hooks/useTypeaheadPseudoRollup/useTypeaheadPseudoRollup';
import { BreadcrumbSessionStorage } from '../../../../models/BreadcrumbSessionStorage';
import { useAnalyticsStore } from '../../../../store/useAnalyticsStore';
import { useChipStore } from '../../../../store/useChipStore';
import { ChipState } from '../../../../store/useChipStore/chipStore';
import { useErrorStore } from '../../../../store/useErrorStore';
import { usePCPStore } from '../../../../store/usePCPStore';
import { useSearchStore } from '../../../../store/useSearchStore';
import { SearchState } from '../../../../store/useSearchStore/searchStore';
import { useTypeaheadStore } from '../../../../store/useTypeaheadStore';
import { LagoonData } from '../../../../utils/common';
import { returnSuppressFlag } from '../../../../utils/featureSuppress';
import {
  getEmployeeAssistanceProgramValue,
  getSuppressFacilityFlags,
  getVirtualCareValue,
} from '../../../../utils/providerDetails.utils';
import {
  getAutoIncrementRadius,
  getLagoonConfigValue,
  getSearchRadius,
} from '../../../../utils/providerSearch.utils';
import {
  getCoverageTypes,
  getCurrentMember,
  getDependentInfo,
  getIsOxfordAndUSP,
  getLoggedInMember,
  getNetworkIdsForPES,
  isOHCNSMember,
} from '../../../../utils/user.utils';
import { NullPageContainerStyled } from '../NullSpecialtyPage/NullSpecialtyPage.styles';
import { GetCategoryCountProps } from './categoryCountTypes';
import { SearchedResultSectionData } from './SearchedResultSectionData';
import {
  getConditionAoeCodes,
  getCorrectChipCategory,
  getFilterValue,
  getGenderCode,
  getPesSearchTerm,
  getSearchTitle,
  getSelectedCoverageType,
  getSpecialtyCode,
  getVirtualCareCode,
  setupAoeCodesForEAP,
} from './SearchResults.UtilsV2';
import {
  getNoOfResultsForAnalytics,
  getProviderSearchPayloadWithRecriprocityId,
  getProviderType,
  getProviderTypesFromDisplayedResults,
  groupSpecialtyRollupCodes,
  resolveCoverageType,
  resolveIncludeSpecialityRollupCodesAndUpdateStore,
} from './searchUtils';
import {
  UseParallelSearchResults,
  useParallelSearchResultsCategoriesCount,
} from './useParallelSearchResultsCategoriesCount';
import { useSearchResultsProviderResults } from './useSearchResultsProviderResults';
import {
  checkForPseudoRollupCodes,
  compareAndReturn,
  getAOEOrSpecialityById,
  getAllProvidersData,
  getDntFiltersFromQueryParams,
  getEapBHProgramId,
  getFirstEnabledChip,
  getModifiedPayloadForProviderSearch,
  getPreferredFacilityFilter,
  isShowProviderGroups,
} from './utils';

interface GlobalSearchParams {
  includeSpecialityRollupCodes: string;
}

export const SearchResultsV2 = () => {
  const { t } = useTranslation();
  const { navigate } = useRouter();
  const [searchParams, setSearchParams] = useSearchParams();
  const storedLocation = useGeoLocationStorage();
  const coverageType = useCoverageType();
  const mobileScreen = useMediaQuery(phoneOnly);
  const { data: specialtyCodes = [] } = useSpecialtiesResult({
    coverageType,
  });

  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const deepLinkMapping = useLagoon(Constants.LAGOON_TABLE.DEEPLINK_MAPPING)();
  const uiMessaging = useLagoon('ui-messaging')();
  const typeaheadPreferenceCodes = useLagoon('typeahead-preference')();
  const allConfigs = useLagoon('config');
  const searchRadius = getLagoonConfigValue(
    allConfigs,
    'DEFAULT_SEARCH_RADIUS'
  );
  const autoIncrementRadius = getLagoonConfigValue(
    allConfigs,
    'AUTO_INCREMENT_SEARCH_RADIUS'
  );
  const suppressFacilityFlags = getSuppressFacilityFlags();
  const suppressPremiumCare = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PREMIUM_CARE
  );
  const [
    cleanUpProviderCalls,
    llmFlag,
    enableBHAoeTypeahead,
    eapBHProgramFlag,
    eapCodeFlag,
    showTierProviderTag,
    enableNavigateToCorrectCategory,
    disablePCPIndAndANP,
    enableBHVirtualcare,
    oxfordPlnFlag,
  ] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.CLEANUP_PROVIDER_CALLS,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_LLM,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_BH_AOE_TYPEAHEAD,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_EAP_FILTER_USING_BH_PROGRAM,
    ConstantsLagoon.FEATURE_FLAGS.EAP_CODE,
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[
      Constants.CHIPS_CATEGORIES.PRIMARY_CARE
    ][SECTION_TERM.TIER_ONE],
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_NAVIGATE_TO_CORRECT_CATEGORY,
    ConstantsLagoon.FEATURE_FLAGS.DISABLE_PCP_IND_AND_ANP,
    ConstantsLagoon.FEATURE_FLAGS.BEHAVIORAL_HEALTH_VIRTUAL_CARE,
    ConstantsLagoon.FEATURE_FLAGS.OXFORD_PLN,
  ]);

  // initialize store values from url searchParams for search results page
  const { providerType = '' } = useInitializeStore(
    ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key
  );
  const { isCountySearch } = useContext(CountySearchContext);
  const { portalData } = useContext(PortalContext);

  const [, getSaveRecentSearches] = useSaveRecentSearches({});
  const [planYear] = usePlanYear();

  const memberHasRPK = useStore(StoreKeys.TIER1_PLAN_FLAG);
  const [globalSeachParams] = useSessionStorage<GlobalSearchParams>(
    Constants.STORAGE_KEYS.SESSION.PSX_GLOBAL_SEARCH,
    { includeSpecialityRollupCodes: '' }
  );
  const [breadcrumbUrls, setBreadcrumbUrls] =
    useSessionStorage<BreadcrumbSessionStorage>(
      Constants.STORAGE_KEYS.SESSION.BREADCRUMB_URLS,
      {}
    );

  const setSpecialtySearch = (isSpecialtySearch: boolean, replace = false) => {
    const searchParamsObject = Object.fromEntries(searchParams.entries());
    setSearchParams(
      {
        ...searchParamsObject,
        searchType: isSpecialtySearch
          ? Constants.SEARCH_TYPES.SPECIALTY
          : Constants.SEARCH_TYPES.NAME,
      },
      { replace }
    );
  };

  const specialtySearch =
    searchParams.get('searchType') === Constants.SEARCH_TYPES.SPECIALTY;

  const isSearchModified = useRef(false);
  const isTypeAheadDataLoaded = useRef(false);
  const isCategoryCountLoaded = useRef(false);
  const isEmptyResults = useRef(false);
  const typeAheadDataRef = useRef<any>({});
  const categoryCountResultsRef = useRef<any>({});
  const previousDataRef = useRef(null);
  const dataRef = useRef(null);
  const typeaheadData = typeAheadDataRef.current;
  const categoryCountResults = categoryCountResultsRef.current;

  const [isLoading, setIsLoading] = useState(true);
  const [isPageTracked, setPageTracked] = useState<boolean>(false);

  const [showProviders, setShowProviders] = useState(true);
  const [showProviderGroups, setShowProviderGroups] = useState(true);
  const [showFacilities, setShowFacilities] = useState(true);

  const uesTranslation = getModuleOrDefault('en', 'uesSpecialityTranslations');
  const aoeTranslations = getModuleOrDefault('en', 'aoeTranslations');
  const specialityCodeTranslation = getModuleOrDefault(
    'en',
    'sourceSystemSpecialityTranslations'
  );

  const { dependentSeqNbr, choosePCP } = usePCPStore(
    useShallow((state: any) => ({
      choosePCP: state.pcpSearchState.choosePCP,
      dependentSeqNbr: state.pcpSearchState.dependentSeqNbr,
    }))
  );
  const {
    linkName = '',
    originLinkNameForAnalytics = '',
    setAnalyticsState,
  } = useAnalyticsStore(
    useShallow((state: any) => ({
      originLinkNameForAnalytics:
        state.analyticsState.originLinkNameForAnalytics,
      linkName: state.analyticsState.linkName,
      setAnalyticsState: state.setAnalyticsState,
    }))
  );

  const { setErrorStore } = useErrorStore(
    useShallow((state: any) => ({
      setErrorStore: state.setErrorStore,
    }))
  );

  const {
    chipValue: chipValue,
    setStoreChipValue,
    setStoreCoverageType,
  } = useChipStore(
    useShallow((state: ChipState) => ({
      chipValue: state.chipValue,
      setStoreChipValue: state.setChipValue,
      setStoreCoverageType: state.setCoverageType,
    }))
  );

  const { setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: any) => ({
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );

  const { onlineRetailers } = useSearchStore(
    useShallow((state: SearchState) => ({
      onlineRetailers: state.searchStore.onlineRetailers,
    }))
  );

  const isGlobalSearch =
    searchParams.get(Constants.SOURCE) === Constants.GLOBAL_SEARCH;

  const OHCNSpolicyIds = find(uiMessaging, {
    key: ConstantsLagoon.UI_MESSAGING.STATE_SPECIFIC_DISCLAIMER,
  })?.policyIDs;
  const isOHCNS = isOHCNSMember(OHCNSpolicyIds);

  const currentMember = dependentSeqNbr
    ? getDependentInfo(dependentSeqNbr)
    : getCurrentMember();
  const { isOXMember } = getIsOxfordAndUSP(
    currentMember,
    coverageType,
    featureFlags
  );

  const ddpCodeUser = getLoggedInMember()?.ddpCode;
  const isPCPLandingPage =
    chipValue === Constants.CHIPS_CATEGORIES.PRIMARY_CARE ? true : false;
  const isPcpIndicator =
    disablePCPIndAndANP && isPCPLandingPage && !choosePCP
      ? null
      : choosePCP || chipValue === CareCategories.PRIMARY_CARE;
  const acceptingNewPatients = isPcpIndicator ? true : undefined;
  const { longitude, latitude, name: location } = storedLocation;
  const dependentInfo = getDependentInfo(dependentSeqNbr);
  const memberCoverageTypes = getCoverageTypes(currentMember);

  const pseudoRollupCodeTable: LagoonData[] = useTypeaheadPseudoRollupTable();

  const onTypeAheadDataLoaded = (tyeaheadData: any) => {
    isTypeAheadDataLoaded.current = true;
    typeAheadDataRef.current = tyeaheadData;
    const {
      searchType = '',
      search = '',
      combinedIncludeSpecialityRollupCodes = '',
      pseudoVerticals = '',
      suggestion = '',
      searchTerm = '',
      keyword = '',
      coverageTypes = '',
      isMixedSuggestions,
      isUserTextSearch,
      bhProgramFacilityAgg,
    } = tyeaheadData;

    const pseudoVerticalsDetails = getTypeaheadPseudoRollup(
      pseudoRollupCodeTable,
      pseudoVerticals,
      suggestion,
      isOXMember,
      oxfordPlnFlag
    );

    const preferredFacilityFilter = getPreferredFacilityFilter(
      pseudoVerticals,
      pseudoVerticalsDetails
    );

    let newRollupcodesFromQueryParams = '';

    if (specialtyCategory && deepLinkMapping && specialtyCodes) {
      const specialtyCategoryIds = getDntFiltersFromQueryParams(
        specialtyCategory,
        deepLinkMapping
      );

      const codes = [] as any;
      specialtyCodes.forEach((item) => {
        if (specialtyCategoryIds.includes(item.id)) {
          codes.push(item.codes);
        }
      });
      newRollupcodesFromQueryParams = codes.flat().join();
    }

    // TODO: getSpecialtyCode is broken, to be fixed
    const specialtyCode: string = getSpecialtyCode(
      pesKeyword,
      searchCategory,
      specialtyCodes
    );

    const includeSpecialityRollupCodesProps =
      resolveIncludeSpecialityRollupCodesAndUpdateStore(
        isGlobalSearch,
        globalSeachParams,
        combinedIncludeSpecialityRollupCodes,
        setTypeaheadSearchStore
      );

    const includeSpecialityRollupCodes =
      (newRollupcodesFromQueryParams.length
        ? newRollupcodesFromQueryParams
        : specialtyCode ?? includeSpecialityRollupCodesProps) || '';

    const categoryCountRequestParams: GetCategoryCountProps = {
      currentMember,
      onlineRetailers,
      featureFlags,
      pseudoVerticalsDetails,
      pseudoRollupCode: !!pseudoVerticalsDetails?.isPseudoRollupSearch,
      preferredFacilities: preferredFacilityFilter?.length
        ? preferredFacilityFilter
        : undefined,
      pseudoVerticals,
      includeSpecialityRollupCodes,
      searchType,
      specialtySearch,
      uesTranslation,
      coverageTypes: resolveCoverageType(coverageTypes),
      keyword,
      search,
      searchTerm,
      isMixedSuggestions,
      isUserTextSearch,
      llmFlag: pseudoVerticalsDetails?.isPseudoRollupSearch ? false : llmFlag,
      behavioralFacilityProgram: bhProgramFacilityAgg,
    };
    getParallelCategoryCountResults(categoryCountRequestParams);
  };

  useTypeAheadData({
    onTypeAheadDataLoaded,
  });
  const {
    searchType = '',
    userZip = '',
    category: searchCategory = '',
    pesKeyword,
    search = '',
    pseudoVerticals = '',
    combinedIncludeSpecialityRollupCodes,
    searchMethod = '',
    searchTerm = '',
    keyword = '',
    medicalSpeciality,
    coverageTypes = '',
    isMixedSuggestions,
    isUserTextSearch,
    suggestion,
    sectionType,
    specialtyCategory,
    condition,
    aoeCodes: typeaheadAoeCodes = [],
    bhProgramFacilityAgg,
    acceptsEap,
    gender,
    virtualCare,
  } = typeaheadData;

  const pseudoVerticalsDetails = useTypeaheadPseudoRollup(
    pseudoVerticals,
    suggestion,
    isOXMember
  );

  const includeSpecialityRollupCodesProps =
    resolveIncludeSpecialityRollupCodesAndUpdateStore(
      isGlobalSearch,
      globalSeachParams,
      combinedIncludeSpecialityRollupCodes,
      setTypeaheadSearchStore
    );

  const eapSpecialitySearch = getEmployeeAssistanceProgramValue(
    pseudoVerticalsDetails,
    PseudoRollupCodeTableKeys.FILTER_NAME
  );

  // Process pre-select EAP filter based on portal config or EAP pseudo code search or therapy drill down
  const preSelectEapFilter = !!(
    portalData?.portalConfig?.preSelectEapFilter ||
    acceptsEap ||
    eapSpecialitySearch
  );

  setupAoeCodesForEAP(eapSpecialitySearch, eapBHProgramFlag, typeaheadAoeCodes);

  const { newRollupcodesFromQueryParams, specialtyCodeFromQuery } =
    useMemo(() => {
      if (specialtyCategory && deepLinkMapping && specialtyCodes) {
        const specialtyCategoryIds = getDntFiltersFromQueryParams(
          specialtyCategory,
          deepLinkMapping
        );

        const specialtyCodeFromQuery = getAOEOrSpecialityById(
          specialtyCategoryIds,
          specialityCodeTranslation
        );

        const codes = [] as any;
        specialtyCodes.forEach((item) => {
          if (specialtyCategoryIds.includes(item.id)) {
            codes.push(item.codes);
          }
        });
        const newRollupcodesFromQueryParams = codes.flat().join();
        return { newRollupcodesFromQueryParams, specialtyCodeFromQuery };
      }
      return { newRollupcodesFromQueryParams: [], specialtyCodeFromQuery: [] };
    }, [specialtyCategory, specialtyCodes]);

  const specialtyCode: string = getSpecialtyCode(
    pesKeyword,
    searchCategory,
    specialtyCodes
  );

  const includeSpecialityRollupCodes = newRollupcodesFromQueryParams.length
    ? newRollupcodesFromQueryParams
    : specialtyCode ?? includeSpecialityRollupCodesProps;
  const hasOnlyPseudoRollupCodes = checkForPseudoRollupCodes(
    includeSpecialityRollupCodes
  );

  const genderCode = gender ? [getGenderCode(gender)] : [];
  const virtualCareCode = virtualCare ? getVirtualCareCode(t) : undefined;
  const { areaOfExpertise, conditionAoeCodes } = getConditionAoeCodes(
    condition,
    deepLinkMapping,
    aoeTranslations
  );

  const searchTitle: string = getSearchTitle(search, searchType, userZip);
  const virtualCareValue = getVirtualCareValue(
    pseudoVerticalsDetails,
    PseudoRollupCodeTableKeys.FILTER_NAME
  );
  const preferredFacilityFilter = getPreferredFacilityFilter(
    pseudoVerticals,
    pseudoVerticalsDetails
  );

  useEffect(() => {
    setStoreCoverageType(
      searchParams.get('coverageType') || ReverseCoverageTypesCodes.MEDICAL
    );
  }, []);

  const uniqueSearch = `${search}-${searchType}-${linkName}-${latitude}-${longitude}`;
  // Set empty results to false and chip category to all, when new search is triggered.
  useEffect(() => {
    isEmptyResults.current = false;
    setStoreChipValue(CareCategories.ALL);
  }, [uniqueSearch]);

  useEffect(() => {
    if (choosePCP) {
      adobePageTrackEvent();
      setPageTracked(true);
    }
  }, [choosePCP]);

  useEffect(() => {
    setPageTracked(false);
  }, [chipValue]);

  const customParams = {
    search: searchTitle,
    ...(conditionAoeCodes.length > 0 && { conditionAoeCodes }),
    isConditionSearch: condition && conditionAoeCodes?.length > 0,
    typeaheadAoeCodes,
    enableBHAoeTypeahead,
    preSelectEapFilter,
    acceptsEap,
    providerType,
    includeSpecialityRollupCodes:
      !isUserTextSearch && includeSpecialityRollupCodes
        ? includeSpecialityRollupCodes
        : '',
    searchType,
    pcpIndicator: isPcpIndicator,
    isPCPLandingPage,
    useNewAgeAndGenderRollups: disablePCPIndAndANP,
    searchRadius: getSearchRadius(searchRadius),
    autoIncrementRadius: getAutoIncrementRadius(autoIncrementRadius),
    acceptingNewPatients,
    ...(dependentSeqNbr && {
      memberDemographics: {
        gender: dependentInfo?.demographics.gender,
        dateOfBirth: dependentInfo?.demographics.dateOfBirth,
      },
    }),
    coverageType,
    keyword,
    onlineRetailers,
    isExtendedSearchEnabled: true,
    isUserTextSearch,
    shouldGetHGData: true,
    ...(choosePCP && {
      reciprocityId: getNetworkIdsForPES(
        currentMember,
        coverageType,
        featureFlags
      ),
    }),
    gender: getFilterValue(typeaheadData, 'gender'),
    virtualCare: getFilterValue(typeaheadData, 'virtualCare'),
    pseudoRollupCode: pseudoVerticalsDetails?.isPseudoRollupSearch,
    preferredFacilities: preferredFacilityFilter?.length
      ? preferredFacilityFilter
      : undefined,
    ...(isEmpty(providerType) && { typeaheadSearch: searchTitle }),
    ...(virtualCareValue &&
      enableBHVirtualcare && {
        virtualCare: virtualCareValue,
      }),
  };

  // This is to load the initial Provider Search Results after Category Count is loaded
  const onCategoryCountLoaded = (
    categoryCountResults: UseParallelSearchResults
  ) => {
    isCategoryCountLoaded.current = true;
    categoryCountResultsRef.current = categoryCountResults;
    const { nameResults, specialityResults } = categoryCountResults;

    const enabledChipForNameSearch = getFirstEnabledChip(nameResults);
    const enabledChipForSpecialitySearch =
      getFirstEnabledChip(specialityResults);

    const hasOnlySpecialitySearchEnabled =
      !enabledChipForNameSearch && !!enabledChipForSpecialitySearch;

    const availableCategoryChips = specialityResults.reduce<string[]>(
      (acc, item) => {
        if (item.chipEnabled) {
          acc.push(TypeaheadChipCategoriesMapping[item.chipCategory]);
        }
        return acc;
      },
      []
    );

    const { combinedIncludeSpecialityRollupCodes } = typeaheadData;

    const navigateToChipCategory = enableNavigateToCorrectCategory
      ? getCorrectChipCategory(
          combinedIncludeSpecialityRollupCodes,
          typeaheadPreferenceCodes,
          specialityResults
        )
      : '';

    const chipToSelect =
      !(hasOnlySpecialitySearchEnabled || specialtySearch) &&
      enabledChipForNameSearch
        ? enabledChipForNameSearch
        : availableCategoryChips.includes(navigateToChipCategory)
        ? navigateToChipCategory
        : enabledChipForSpecialitySearch;

    if (chipToSelect) {
      setSpecialtySearch(
        hasOnlySpecialitySearchEnabled || specialtySearch,
        true
      );
      setStoreChipValue(chipToSelect);
      const selectedCoverageType = getSelectedCoverageType(chipToSelect);
      setStoreCoverageType(ReverseCoverageTypesCodes[selectedCoverageType]);
      if (chipToSelect === chipValue) {
        getDataForProviders(specialtySearch, chipToSelect);
      }
      // We need this setTimeout for below reasons.
      // Apollo does not trigger onCompleted when the data is the same as the previous data
      // So, we are setting the isLoading to false after 1 second if the data is the same as the previous data
      setTimeout(() => {
        if (dataRef.current === previousDataRef.current) {
          setIsLoading(false);
        }
        previousDataRef.current = null;
      }, 1000);
    } else {
      setIsLoading(false);
      isEmptyResults.current = true;
      previousDataRef.current = dataRef.current;
    }
  };

  // This is to set the loading state to false after the Provider Search Results are loaded
  const onProviderSearchResultsLoaded = () => {
    // setIsLoading(false);
  };

  // TODO: All Custom Params should be moved to fetch variables
  const [parallelCategoryCountResults, getParallelCategoryCountResults] =
    useParallelSearchResultsCategoriesCount(
      customParams,
      onCategoryCountLoaded
    );
  const {
    data,
    error,
    providerIsLoading,
    providersResult,
    medGrpResult,
    organizationResult,
    orgIsLoading,
    medGrpIsLoading,
    providerHasError,
    getProviderSearchResults,
  } = useSearchResultsProviderResults(
    customParams,
    onProviderSearchResultsLoaded
  );

  const { specialityResults, nameResults } = categoryCountResults;

  useEffect(() => {
    if (JSON.stringify(dataRef.current) !== JSON.stringify(data)) {
      setIsLoading(false);
      dataRef.current = data;
    }
  }, [JSON.stringify(data)]);

  const allProviders = getAllProvidersData(data, chipValue);
  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: 'overview',
    sitesectionLevel1: 'search results',
    sitesectionLevel2: 'category',
    providerBlock: {
      type: convertCoverageType(coverageType),
    },
    impressionBlock: {
      type: 'provider',
      indicator:
        allProviders?.length > 0
          ? getIndicatorsForImpressions(
              allProviders,
              featureFlags,
              false,
              {
                suppressPremiumCare,
                ddpCodeUser,
                suppressFacilityFlags,
                memberHasRPK,
                isCountySearch,
              },
              currentMember
            )
          : '',
      providerTier: getTier1Indicator(showTierProviderTag, allProviders),
    },
  });

  const getProviderSearchPayload = (
    payloadWithRecriprocityId,
    isSpecialtySearch,
    searchType,
    isUserTextSearch,
    typeaheadAoeCodes,
    enableBHAoeTypeahead
  ) => {
    const codeByGroup = !pseudoVerticalsDetails?.isPseudoRollupSearch
      ? groupSpecialtyRollupCodes(
          includeSpecialityRollupCodes,
          searchType,
          isSpecialtySearch
        )
      : undefined;
    return getModifiedPayloadForProviderSearch(
      payloadWithRecriprocityId,
      codeByGroup,
      uesTranslation,
      search,
      keyword,
      isUserTextSearch,
      resolveCoverageType(coverageTypes),
      typeaheadAoeCodes,
      enableBHAoeTypeahead,
      searchType,
      bhProgramFacilityAgg
    );
  };

  const getDataForProviders = (
    isSpecialtySearch: boolean,
    chipToUse: string
  ) => {
    if (
      !choosePCP &&
      !(
        customParams.search ||
        customParams.includeSpecialityRollupCodes ||
        customParams.preferredFacilities?.length
      )
    ) {
      return;
    }
    const searchTypeToUse = isSpecialtySearch
      ? Constants.SEARCH_TYPES.SPECIALTY
      : Constants.SEARCH_TYPES.NAME;

    const payloadWithRecriprocityId =
      getProviderSearchPayloadWithRecriprocityId(
        memberCoverageTypes,
        featureFlags,
        currentMember,
        coverageType,
        choosePCP,
        onlineRetailers
      );
    const payload = getProviderSearchPayload(
      payloadWithRecriprocityId,
      isSpecialtySearch,
      searchTypeToUse,
      isUserTextSearch,
      typeaheadAoeCodes,
      enableBHAoeTypeahead
    );

    const isPCPLandingPage =
      chipToUse === Constants.CHIPS_CATEGORIES.PRIMARY_CARE ? true : false;
    const isPcpIndicator =
      disablePCPIndAndANP && isPCPLandingPage && !choosePCP
        ? null
        : choosePCP || chipToUse === CareCategories.PRIMARY_CARE;
    const acceptingNewPatients = isPcpIndicator ? true : undefined;

    const selectedCategory = Constants.CARE_CATEGORIES_TYPEAHEAD.find(
      (category) => category.label === chipToUse
    );
    const selectedCategoryPayload = payload.find(
      (item) => item.chipCategory === selectedCategory?.id
    );

    const providerQueryTypes: any =
      specialityResults?.filter(
        (item) => item.chipCategory === selectedCategory?.id
      )[0]?.providerTypes || [];

    getProviderSearchResults({
      setShowFacilities,
      setShowProviderGroups,
      setShowProviders,
      providerSearchParams: {
        ...customParams,
        isPCPLandingPage,
        pcpIndicator: isPcpIndicator,
        acceptingNewPatients,
        searchType: searchTypeToUse,
      },
      choosePCP,
      cleanUpProviderCalls,
      pseudoVerticalsDetails,
      selectedCategory,
      selectedCategoryPayload,
      specialtySearch: isSpecialtySearch,
      searchType: searchTypeToUse,
      providerQueryTypes,
    });
    setTypeaheadSearchStore({ providerQueryTypes });
  };

  useEffect(() => {
    if (
      isTypeAheadDataLoaded.current &&
      isCategoryCountLoaded.current &&
      chipValue !== CareCategories.ALL &&
      !choosePCP
    ) {
      getDataForProviders(specialtySearch, chipValue);
    }
  }, [chipValue, choosePCP, searchType]);

  useEffect(() => {
    if (choosePCP) {
      getDataForProviders(
        searchType == Constants.SEARCH_TYPES.SPECIALTY,
        CareCategories.PRIMARY_CARE
      );
    }
  }, [choosePCP, latitude, longitude]);

  const recentSearchArgs = {
    psxSearchTerm: search,
    pseudoVerticals,
    location,
    latitude,
    longitude,
    searchType,
    pesSearchTerm: getPesSearchTerm(
      bhProgramFacilityAgg,
      includeSpecialityRollupCodes,
      search
    ),
    isMixedSearchSuggestion: compareAndReturn(isMixedSuggestions, false),
    isUserTextSearch: compareAndReturn(isUserTextSearch, false),
    gender: getFilterValue(typeaheadData, 'gender'),
    virtualCare: getFilterValue(typeaheadData, 'virtualCare'),
    conditions: condition,
    specialtyCategories: specialtyCategory,
    bhProgramFacilityAgg,
    aoeCodes: typeaheadAoeCodes,
    behavioralProgramId: acceptsEap ? [getEapBHProgramId()] : null,
  };

  const enabledChipForNameSearch = getFirstEnabledChip(nameResults);
  const enabledChipForSpecialitySearch = getFirstEnabledChip(specialityResults);

  useEffect(() => {
    const isSuperUser = storage.session.get(
      Constants.STORAGE_KEYS.SESSION.SUPER_USER
    );
    const isGuestUser = storage.session.get(
      Constants.STORAGE_KEYS.SESSION.IS_GUEST
    );
    if (!isSuperUser && !isGuestUser && recentSearchArgs.psxSearchTerm) {
      const getChipCategoriesCountResults =
        enabledChipForNameSearch || !enabledChipForSpecialitySearch
          ? nameResults
          : specialityResults;

      getSaveRecentSearches({
        variables: {
          ...recentSearchArgs,
          category: getCategoryFromChipCategoriesCount(
            getChipCategoriesCountResults
          ),
          coverageType: compareAndReturn(coverageType, 'M'),
          isMixedSearchSuggestion: !!enabledChipForNameSearch,
          searchType: enabledChipForNameSearch
            ? Constants.SEARCH_TYPES.NAME
            : Constants.SEARCH_TYPES.SPECIALTY,
          planYear,
        },
      });
    }
  }, [JSON.stringify(parallelCategoryCountResults)]);

  useEffect(() => {
    const totalNumberOfResults = getNoOfResultsForAnalytics(data);
    if (!isLoading && chipValue && !choosePCP && totalNumberOfResults > 0) {
      const adobeSearchMethod = compareAndReturn(searchMethod, 'guided');
      const adobeSearchTerm =
        adobeSearchMethod === 'guided'
          ? ''
          : compareAndReturn(searchTerm, search);
      const adobeEventBody = {
        term: adobeSearchTerm,
        type: getProviderType(providerType),
        linkName: originLinkNameForAnalytics,
        method: adobeSearchMethod,
        filters: chipValue?.toLowerCase(),
        numberOfResults: getNoOfResultsForAnalytics(data),
        customAttributesBlock: {
          providerType: getProviderTypesFromDisplayedResults(data),
          searchLocation: getSearchLocation(storedLocation),
        },
      };

      adobePageTrackEvent();
      setPageTracked(true);

      if (!isSearchModified.current) {
        adobeStandardSearchEvent(adobeEventBody);
        isSearchModified.current = true;
      } else {
        adobeSearchModifiedEvent(adobeEventBody);
      }

      if (isMixedSuggestions && specialtySearch) {
        adobeImpressionTrackEvent({
          type: 'search modify',
          message:
            'search instead for providers that include typed term in their name',
          searchTerm: adobeSearchTerm,
          method: adobeSearchMethod,
        });
      }
      if (isMixedSuggestions && !specialtySearch) {
        adobeImpressionTrackEvent({
          type: 'search modify',
          message: 'search instead for providers that can treat typed term',
          searchTerm: adobeSearchTerm,
          method: adobeSearchMethod,
        });
      }
    }
  }, [
    providerIsLoading,
    medGrpIsLoading,
    orgIsLoading,
    specialtySearch,
    chipValue,
  ]);

  const noResultUrlParams = convertObjectToUrlParams({} as ChipState, {
    chipValue,
    includeSpecialityRollupCodes,
    linkName,
    medicalSpeciality,
    originLinkNameForAnalytics,
    search,
    searchType,
    sectionType,
    specialtyCode,
  });

  const showMedicalSpecialityNSRPage =
    medicalSpeciality &&
    chipValue === Constants.CHIPS_CATEGORIES.MEDICAL_SPECIALISTS;

  useEffect(() => {
    if (!isLoading && isEmptyResults.current && showMedicalSpecialityNSRPage) {
      navigate(
        `${ConstantsRoutes.NULL_SPECIALTY_RESULTS.path}/${noResultUrlParams}`
      );
    }
    if (error) {
      const errorStoreValues = {
        uiSection: linkName || originLinkNameForAnalytics,
      };
      setErrorStore(errorStoreValues);

      const urlParams = convertObjectToUrlParams({} as ChipState, {
        ...errorStoreValues,
      });

      navigate(
        `${
          errorCodeRouteMap[providerHasError?.status] ||
          errorCodeRouteMap['4xx']
        }${urlParams}`
      );
    }
  }, [isLoading, error, showMedicalSpecialityNSRPage, noResultUrlParams]);

  useEffect(() => {
    document.title = chipValue
      ? `${t(`${chipValue}-results-for`)} ${searchTitle} | ${
          Constants.SITE_NAME
        }`
      : Constants.SITE_NAME;
  }, [chipValue, searchTitle]);

  useEffect(() => {
    breadcrumbUrls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key] =
      ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path + window.location.search;
    const updatedBreadCrumbUrls = pick(
      breadcrumbUrls,
      ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key
    );
    setBreadcrumbUrls(updatedBreadCrumbUrls);
  }, [searchParams]);

  const onSpecialtyResultClicked = (
    isSpecialtySearch: boolean,
    title: string = ''
  ) => {
    setSpecialtySearch(isSpecialtySearch);

    const navigateToChipCategory = enableNavigateToCorrectCategory
      ? getCorrectChipCategory(
          combinedIncludeSpecialityRollupCodes,
          typeaheadPreferenceCodes,
          specialityResults
        )
      : '';

    const firstEnabledChip = isSpecialtySearch
      ? enabledChipForSpecialitySearch
      : enabledChipForNameSearch;
    const chipToSelect =
      isSpecialtySearch && navigateToChipCategory
        ? navigateToChipCategory
        : firstEnabledChip;

    const selectedCoverageType = getSelectedCoverageType(chipToSelect);
    setStoreCoverageType(ReverseCoverageTypesCodes[selectedCoverageType]);
    setStoreChipValue(chipToSelect);

    adobeLinkTrackEvent({
      name: title,
      location: 'body:result heading',
    });
    setAnalyticsState({ originLinkNameForAnalytics: title });
  };

  const getChipsToShowOnHeader = () => {
    if (enabledChipForNameSearch && !specialtySearch) {
      return nameResults || [];
    }
    return specialityResults || [];
  };

  const breadcrumbs = [
    {
      title: t('BC Results'),
      href: '',
    },
  ];

  const llmFlagValue = pseudoVerticalsDetails?.isPseudoRollupSearch
    ? false
    : llmFlag;

  return (
    <React.Fragment>
      {!choosePCP ? (
        <PSXHeader
          breadcrumbs={breadcrumbs}
          categoryCount={getChipsToShowOnHeader()}
          dataTestId="search-results-search-form"
          isMixedResultsPage
          showChips={!isEmptyResults.current}
          showChoosePCPHeader={false}
          showMemberSelection={!mobileScreen}
          showSearchInputBackButton={mobileScreen}
        />
      ) : (
        <PSXHeader
          breadcrumbs={breadcrumbs}
          dataTestId="search-results-search-form"
          showChips={false}
          showChoosePCPHeader
          showMemberSelection={false}
        />
      )}
      {isLoading ? (
        <SectionSkeleton />
      ) : isEmptyResults.current && !showMedicalSpecialityNSRPage ? (
        <NullResultsPage
          emptyResults={isEmptyResults.current}
          onCommonSearchChipClicked={() => {
            isEmptyResults.current = false;
            setIsLoading(true);
          }}
          searchTitle={searchTitle}
          setSpecialtySearch={setSpecialtySearch}
          specialtyCode={specialtyCode}
          specialtySearch={specialtySearch}
        />
      ) : (
        <SearchedResultSectionData
          acceptingNewPatients={acceptingNewPatients}
          areaOfExpertise={areaOfExpertise}
          categoryCount={getChipsToShowOnHeader()}
          choosePCP={choosePCP || false}
          contextValue={chipValue}
          data={data}
          dependentSeqNbr={dependentSeqNbr || ''}
          eapCodeFlag={eapCodeFlag}
          facilitiesHeaders={organizationResult.headers}
          genderCode={genderCode}
          hasOnlyPseudoRollupCodes={hasOnlyPseudoRollupCodes}
          isMixedResultsPage
          isMixedSuggestions={isMixedSuggestions}
          isNameSearchEnabled={enabledChipForNameSearch !== ''}
          isPageTracked={isPageTracked}
          isSpecialtySearchEnabled={
            hasOnlyPseudoRollupCodes || enabledChipForSpecialitySearch !== ''
          }
          llmFlag={llmFlagValue}
          medGrpIsLoading={medGrpIsLoading}
          medicalGroupHeaders={medGrpResult.headers}
          onSpecialtyResultClicked={onSpecialtyResultClicked}
          orgIsLoading={orgIsLoading}
          preSelectEapFilter={preSelectEapFilter}
          preferredFacility={customParams.preferredFacilities}
          providerHeaders={providersResult.headers}
          providerIsLoading={providerIsLoading}
          resultType={chipValue}
          searchMethod={searchMethod}
          searchTerm={searchTerm}
          searchTitle={searchTitle}
          searchType={searchType}
          showChips={!isEmptyResults.current}
          showFacilities={showFacilities}
          showProviderGroups={isShowProviderGroups(
            showProviderGroups,
            currentMember?.lineOfBusiness,
            choosePCP
          )}
          showProviders={showProviders}
          specialtyCode={specialtyCode}
          specialtyCodeFromQuery={specialtyCodeFromQuery}
          specialtyResults={parallelCategoryCountResults?.specialityResults}
          specialtySearch={specialtySearch}
          userZip={userZip}
          virtualCare={virtualCareValue}
          virtualCareCode={virtualCareCode}
        />
      )}
      <NullPageContainerStyled hidden={!isEmptyResults.current}>
        <StillNeedHelp />
        {isOHCNS && <DisclaimersContainer />}
      </NullPageContainerStyled>
    </React.Fragment>
  );
};
