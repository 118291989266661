import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { styled } from '@abyss/web/tools/styled';
import { Card } from '@abyss/web/ui/Card';
import { Divider } from '@abyss/web/ui/Divider';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Link } from '@abyss/web/ui/Link';
import find from 'lodash/find';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { phoneOnly } from '../../../../common/ConstantsStyles';
import { getFeatureFlag } from '../../../../common/Utils';
import { useLagoon } from '../../../../hooks/useLagoon';
import { DateTime } from '../../../../utils/DateTime';
import {
  getCoverageTypes,
  getCurrentMember,
} from '../../../../utils/user.utils';
import { TimeStampContent } from './TimeStampContent';

const CardContainer = styled('div', {
  marginTop: 0,
  '.abyss-card-root': {
    backgroundColor: '#F4F6FA',
    border: 'none',
    boxShadow: 'none',
    paddingTop: '$md',
    paddingBottom: '$md',
    display: 'flex',
    '@screen < $md': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  '.text-section': {
    '@screen < $md': {
      paddingTop: '$sm',
    },
    p: {
      lineHeight: '20px',
      fontSize: '$md',
      marginBottom: '0px',
    },
  },
  '.divider': {
    paddingTop: '0px',
    marginRight: '30px',
    '@screen < $md': {
      marginTop: '$xs',
      paddingTop: '$sm',
      marginRight: '0px',
    },
  },
  '.date-info': {
    paddingTop: '$lg',
    display: 'flex',
    '@screen < $md': {
      display: 'block',
    },
  },
});

const DirectorySection = styled('div', {
  marginLeft: '0px',
  width: '35%',
  '@screen < $md': {
    width: '45%',
  },
  '@screen < $sm': {
    width: '100%',
  },
});
const DateInfoText = styled('div', {
  display: 'flex',
  alignItems: 'center',
  fontSize: '$dateInfoText',
  '@screen < $xl': {
    alignItems: 'flex-start',
  },
  '@screen < $md': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: '14.22px',
  },
});
const DotContainer = styled('div', {
  width: '4px',
  height: '4px',
  background: '#B1B2B4',
  borderRadius: '10px',
  marginRight: '8px',
  marginLeft: '8px',
  '@screen < $xl': {
    marginTop: '8px',
  },
  '@screen < $md': {
    display: 'none',
  },
});
const TimeStampContainer = styled('div', {
  maxWidth: '840px',
});

const TimeStampInnerContainer = styled('div', {
  maxWidth: '797px',
});

function getFormattedDate(): string {
  return DateTime.getCurrentDateTime().toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
}

function isDentalTimestampEnabled(
  memberCoverages: any,
  featureFlags: any
): boolean {
  const dentalChipEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DENTAL_CHIP_ENABLED
  );
  return dentalChipEnabled && memberCoverages?.find((cov) => cov === 'D');
}

function isVisionTimestampEnabled(
  memberCoverages: any,
  featureFlags: any
): boolean {
  const visionChipEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.VISION_CHIP_ENABLED
  );
  return visionChipEnabled && memberCoverages?.find((cov) => cov === 'V');
}

export const DirectoryUpdate = () => {
  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();
  const isPhoneViewPort = useMediaQuery(phoneOnly);
  const currentMember = getCurrentMember();
  const memberCoverages = getCoverageTypes(currentMember);
  const showPrintShareDirectory: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.PRINT_SHARE_DIRECTORY
  );

  const disableTimestamp: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISABLE_TIMESTAMP
  );
  const disableMedicalTimestamp: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISABLE_MEDICAL_TIMESTAMP
  );
  const enableTimestampContent = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_TIMESTAMP_CONTENT
  );
  const enableUpdatedTimeStampContent = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_UPDATED_TIME_STAMP_CONTENT
  );
  const data = useLagoon('ui-messaging')();
  const timestampLabel = find(data, {
    key: ConstantsLagoon.UI_MESSAGING.TIME_STAMP_LABEL,
  })?.message;
  const timestampContent = find(data, {
    key: ConstantsLagoon.UI_MESSAGING.TIME_STAMP_CONTENT,
  })?.message;
  const dentalTimestampEnabled = isDentalTimestampEnabled(
    memberCoverages,
    featureFlags
  );
  const visionTimestampEnabled = isVisionTimestampEnabled(
    memberCoverages,
    featureFlags
  );

  const formattedDate = getFormattedDate();

  const { t } = useTranslation();

  return (
    <CardContainer>
      <Card>
        <Card.Section
          css={{
            'abyss-card-section': {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingTop: '0',
              paddingBottom: '0',
              paddingLeft: 0,
              '@screen < $sm': {
                flexDirection: 'column',
              },
            },
          }}
        >
          {showPrintShareDirectory ? (
            <React.Fragment>
              <DirectorySection>
                <Link
                  color="$interactive1"
                  css={{
                    'abyss-link-root': {
                      textAlign: 'left',
                    },
                  }}
                  data-auto-testid="directory-update-print-share"
                  data-testid="directory-update-print-share"
                  fontWeight="bold"
                  isStandardAnchor
                  size="md"
                >
                  {t('Print or Share Directory')}
                  <IconMaterial
                    color="$interactive1"
                    css={{
                      'abyss-icon-material': {
                        marginLeft: 8,
                      },
                    }}
                    icon="arrow_forward_ios"
                    size={12}
                  />
                </Link>
              </DirectorySection>
              <div className="divider">
                <Divider
                  height={isPhoneViewPort ? 1 : 80}
                  orientation={isPhoneViewPort ? 'horizontal' : 'vertical'}
                  width={isPhoneViewPort ? 'auto' : 1}
                />
              </div>
            </React.Fragment>
          ) : null}
          {enableUpdatedTimeStampContent ? (
            <TimeStampContent />
          ) : (
            <TimeStampContainer className="text-section">
              <TimeStampInnerContainer>
                <>
                  {enableTimestampContent ? (
                    <p>
                      <strong
                        data-auto-testid="directory-update-timestamp"
                        data-testid="directory-update-timestamp"
                      >
                        {timestampLabel}:
                      </strong>{' '}
                      {timestampContent}
                    </p>
                  ) : (
                    <p>
                      <strong
                        data-auto-testid="directory-update-timestamp"
                        data-testid="directory-update-timestamp"
                      >
                        {t('timestampLabel')}:
                      </strong>{' '}
                      {t('timestampInfo')}
                    </p>
                  )}
                </>
              </TimeStampInnerContainer>

              {!disableTimestamp ? (
                <div
                  className="date-info"
                  data-auto-testid="directory-last-updated-container"
                  data-testid="directory-last-updated-container"
                >
                  {!disableMedicalTimestamp ? (
                    <DateInfoText>
                      {`${t('Medical information')} - ${formattedDate}`}
                      <DotContainer />
                    </DateInfoText>
                  ) : null}
                  <DateInfoText>
                    {`${t('Behavioral Health information')} - ${formattedDate}`}
                  </DateInfoText>
                  {dentalTimestampEnabled ? (
                    <DateInfoText
                      data-auto-testid="dental-information-date-stamp-psx-footer"
                      data-testid="dental-information-date-stamp-psx-footer"
                    >
                      <DotContainer />
                      {`${t('Dental information')} - ${formattedDate}`}
                    </DateInfoText>
                  ) : null}
                  {visionTimestampEnabled ? (
                    <DateInfoText
                      data-auto-testid="vision-information-date-stamp-psx-footer"
                      data-testid="vision-information-date-stamp-psx-footer"
                    >
                      <DotContainer />
                      {`${t('Vision information')} - ${formattedDate}`}
                    </DateInfoText>
                  ) : null}
                </div>
              ) : null}
            </TimeStampContainer>
          )}
        </Card.Section>
      </Card>
    </CardContainer>
  );
};
