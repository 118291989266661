import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';

import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../Constants';
import { cardHeaderMyBenefits, phoneOnly } from '../ConstantsStyles';
import { ContentWrapper } from '../ContentWrapper';
import {
  BoxStyles,
  ChipContainer,
  ContainerStyle,
  textContent,
} from './StillNeedHelp.style';

export const StillNeedHelp = () => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const largeScreen = useMediaQuery('(max-width: 1100px)');

  const [chatAvailable, setChatAvailable] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const lpmContainerSelector = '[id^="LPMcontainer-"]';
      if (document.querySelector(lpmContainerSelector) != null) {
        setChatAvailable(true);
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const list = [
    {
      title: t('STILL_NEED_HELP.TITLE_PHONE'),
      content1: t('STILL_NEED_HELP.CONTENT_PHONE1'),
      content2: t('STILL_NEED_HELP.CONTENT_PHONE2'),
      icon: 'phone',
      href: `tel:18778444999`,
      adobeLikType: 'phone',
      dataTestId: 'call-us-card',
      display: true,
      divId: 'call-us',
      key: '1',
    },
  ];

  const handleClick = (dataTestId, adobeLinkType) => {
    adobeLinkTrackEvent({
      name: dataTestId,
      location: 'body:still need help',
      type: adobeLinkType,
    });
  };

  return (
    <React.Fragment>
      <Box color="$gray1" css={BoxStyles}>
        <ContentWrapper>
          <Heading
            css={cardHeaderMyBenefits}
            data-auto-testid="still-need-help-heading"
            data-testid="still-need-help-heading"
            display="h4"
            offset={1}
          >
            {t('STILL_NEED_HELP.HEADING')}
          </Heading>
          <ChipContainer>
            <div id={Constants.LIVE_PERSON_EMBEDDED_CHAT_DIV_ID} />
            {!chatAvailable &&
              list.map(
                (item) =>
                  item.display && (
                    <ContainerStyle
                      data-auto-testid={`${item.dataTestId}`}
                      data-testid={`${item.dataTestId}`}
                      href={item.href}
                      id={item.divId}
                      onClick={() =>
                        handleClick(item.dataTestId, item.adobeLikType)
                      }
                    >
                      {' '}
                      <Flex
                        alignItems="center"
                        css={{ gap: '10px', width: '85%' }}
                      >
                        <IconBrand
                          icon={item.icon}
                          size={48}
                          variant="twotone"
                        />
                        <Layout.Stack alignItems="left" css={textContent}>
                          <Text
                            fontWeight={700}
                            size={mobileScreen ? '$md' : '$lg'}
                          >
                            {item.title}
                          </Text>
                          <Flex direction={largeScreen ? 'column' : 'row'}>
                            <Text
                              fontWeight={500}
                              size={mobileScreen ? '$sm' : '$md'}
                            >
                              {item.content1}
                            </Text>
                            <Text
                              fontWeight={500}
                              size={mobileScreen ? '$sm' : '$md'}
                            >
                              &nbsp;{item.content2}
                            </Text>
                          </Flex>
                        </Layout.Stack>
                      </Flex>
                      <Button rounded size="$sm" variant="ghost">
                        <IconMaterial icon="chevron_right" />
                      </Button>
                    </ContainerStyle>
                  )
              )}
          </ChipContainer>
        </ContentWrapper>
      </Box>
    </React.Fragment>
  );
};
