import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { CareCategories } from '../../common/Constants';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../common/Utils';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { useLagoon } from '../useLagoon';

export const useNotAvailableUnknownText = () => {
  const { t } = useTranslation();
  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const featureFlags = useLagoon('feature-flags')();
  const isNotAvailableUnknownTextEnabled = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_NOT_AVAILABLE_UNKNOWN_TEXT
  );

  const isHealthSupplies =
    chipStore?.chipValue === CareCategories.HEALTH_SUPPLIES;

  const providerLocations =
    isNotAvailableUnknownTextEnabled && !isHealthSupplies
      ? t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE_UNKNOWN')
      : t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE');

  const facilityDetails =
    isNotAvailableUnknownTextEnabled && !isHealthSupplies
      ? t('FACILITY_DETAILS.QUALITY_TAB.ACCREDITATION_NOT_FOUND_UNKNOWN')
      : t('FACILITY_DETAILS.QUALITY_TAB.ACCREDITATION_NOT_FOUND');

  const providerDetails =
    isNotAvailableUnknownTextEnabled && !isHealthSupplies
      ? t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE_UNKNOWN')
      : t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NOT_AVAILABLE');

  const virtualGroup =
    isNotAvailableUnknownTextEnabled && !isHealthSupplies
      ? t('VIRTUAL_GROUP_PROVIDER_ABOUT.NOT_AVAILABLE_UNKNOWN')
      : t('VIRTUAL_GROUP_PROVIDER_ABOUT.NOT_AVAILABLE');

  const commonStyling = {
    fontSize: '16px',
    textlign: 'left',
    color: '#323333',
    lineHeight: '20px',
  };

  const descriptionStyle =
    isNotAvailableUnknownTextEnabled
      ? {
          ...commonStyling,
          fontWeight: '400',
        }
      : {};
  const headingStyle =
    isNotAvailableUnknownTextEnabled
      ? {
          ...commonStyling,
          fontWeight: '700',
        }
      : {};

  return {
    providerLocations,
    facilityDetails,
    providerDetails,
    virtualGroup,
    descriptionStyle,
    headingStyle,
    isNotAvailableUnknownTextEnabled,
  };
};
