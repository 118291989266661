import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNotAvailableUnknownText } from '../../../../hooks/useNotAvailableUnknownText';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { phoneOnly } from '../../../ConstantsStyles';
import { CopyToClipBoard } from '../../ProviderLocationsDetails/CopyToClipBoard';
import { infoContainer } from '../../ProviderLocationsDetails/ProviderLocationsDetails.style';
import {
  CollapseHospitalAffiliationsStyle,
  OtherDetailsContentTitle,
} from '../About.styled';

type Props = {
  idNumber?: string;
  idName?: null | string;
};
export const ListOtherProviderDetails = ({ idNumber, idName }: Props) => {
  const { t } = useTranslation();
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const phoneScreen = useMediaQuery(phoneOnly) || isWidget;

  const { providerDetails: notAvailableOrUnknownText } =
    useNotAvailableUnknownText();

  return (
    <React.Fragment>
      <OtherDetailsContentTitle
        data-testid={idName?.toLocaleLowerCase()?.replaceAll(' ', '-')}
      >
        {idName}
      </OtherDetailsContentTitle>
      {idNumber ? (
        <Layout.Stack alignItems="left" space={2}>
          <Flex css={CollapseHospitalAffiliationsStyle}>
            <Text
              color="$info1"
              css={infoContainer(phoneScreen)}
              data-testid={`${idName
                ?.toLocaleLowerCase()
                ?.replaceAll(' ', '-')}-value`}
            >
              {' '}
              {idNumber}{' '}
            </Text>
            <CopyToClipBoard
              label={
                t(
                  `PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.Copy ${idName} to clipboard`
                ) || ''
              }
              text={idNumber}
              title={idName || ''}
            />
          </Flex>
        </Layout.Stack>
      ) : (
        <Text color="$info1" css={infoContainer(phoneScreen)}>
          {notAvailableOrUnknownText}
        </Text>
      )}
    </React.Fragment>
  );
};
