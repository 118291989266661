import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CulturalCompetencyTraining } from '../modal/CulturalCompetencyTraining';
import { distanceInfoIconText } from '../RadioGroup/DistanceInfoIcon.styled';

export const CulturalCompetencyTrainingIcon = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Flex
        css={{
          'abyss-flex-root': {
            padding: '16px 0px 0px 0px',
          },
        }}
        data-testid="cultural-competency-training-info-icon-popover"
        onClick={() => setIsModalOpen(true)}
      >
        <IconMaterial
          css={{
            'abyss-icon-material': {
              padding: '0px 6px 0px 0px',
              marginLeft: '6px',
            },
          }}
          icon="info"
          size={24}
          variant="outlined"
        />
        <Text css={distanceInfoIconText}>
          {t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.CULTURAL_COMPETENCE')}
        </Text>
      </Flex>
      {isModalOpen ? (
        <CulturalCompetencyTraining
          isOpen={isModalOpen}
          setIsOpen={(state: boolean) => setIsModalOpen(state)}
        />
      ) : null}
    </React.Fragment>
  );
};
