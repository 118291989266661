import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Alert } from '@abyss/web/ui/Alert';
import { Button } from '@abyss/web/ui/Button';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import { useCurrentMemberIsPreEffective } from '../../hooks/useCurrentMemberIsPreEffective';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { useLagoon } from '../../hooks/useLagoon';
import { usePrimaryCare } from '../../hooks/usePrimaryCare';
import { BreadcrumbSessionStorage } from '../../models/BreadcrumbSessionStorage';
import { FacilityDDPCode } from '../../models/Provider';
import { ProviderLocation, ProviderType } from '../../models/ProviderDetails';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { usePCPStore } from '../../store/usePCPStore';
import { PCPSearchStore } from '../../store/usePCPStore/pcpStore';
import { searchResultBreadcrumbTitle } from '../../utils/common';
import {
  returnSuppressFlag,
  suppressCostEstimatesForPolicies,
} from '../../utils/featureSuppress';
import { isVirtualCareIconFeatureEnabledOnDetailsHeader } from '../../utils/indicator.utils';
import {
  getDetailsNormalizedProviderId,
  getProviderPCPId,
  getSelectedLocationIndex,
  getSuppressFacilityFlags,
  isShowPreferredFacilityBadge,
  showPremiumFacilityBadgeOnHeader,
  showTier2ProviderTagOnHeader,
  showTierProviderTagOnHeader,
} from '../../utils/providerDetails.utils';
import { parseProviderName } from '../../utils/providerNames.utils';
import { getMemberHealthCoverageType } from '../../utils/providerSearch.utils';
import {
  getCurrentMember,
  getCurrentMemberPCPInfo,
  getIsOxfordAndUSP,
  getLoggedInMember,
} from '../../utils/user.utils';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { Constants, CoverageTypesCodes, LOB } from '../Constants';
import { FeatureFlags } from '../ConstantsFeatureFlags';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { ConstantsRoutes } from '../ConstantsRoutes';
import { hideScrollbar, mobileOnly } from '../ConstantsStyles';
import { ContentWrapper } from '../ContentWrapper';
import { Breadcrumb } from '../PSXHeader/Breadcrumb';
import { ShareThisProviderDrawer } from '../ShareThisProvider/ShareThisProvider';
import { convertObjectToUrlParams, getFeatureFlag } from '../Utils';
import {
  dateFormatToDate,
  getFormattedDate,
} from '../Utils/datesUtils/formatDate';
import {
  getTierProviderEligibility,
  isIfpMember,
} from '../Utils/memberUtils/memberUtils';
import { checkTerminationDate } from '../Utils/terminateDateIndicatorUtils';
import { BenefitsBadge, BenefitsBadgeType } from './BenefitsBadge';
import { DetailsPageChips } from './DetailsPageChips';
import {
  AlertStyling,
  ChoosePCPButtonContainer,
  DetailsHeaderSection,
  HeaderContainer,
  HeaderContentWrapper,
  HeaderFlex,
  HeaderPageDetails,
  InteractButton,
  InteractSection,
  PopoverWrapper,
  ProviderUpdatedSubsection,
  StyledViewIdBannerBox,
  StyledViewIdBannerFlex,
  SuggestEditLink,
} from './DetailsPageHeader.styled';
import { DetailsPagePcpAlerts } from './DetailsPagePcpAlerts';
import { DetailsSection } from './DetailsSection';
import { PcPNotChosenBanner } from './PcPNotChosenBanner';
import { ProviderDetailBanner } from './ProviderDetailBanner';
import { SuggestEditDrawerCard } from './SuggestEditDrawerCard';

export type Props = {
  avatar?: string;
  inNetwork: string;
  rating?: string;
  npi?: string;
  practiceId?: string;
  providerId: string;
  providerName?: string;
  providerType?: ProviderType;
  primarySpeciality: string[];
  professionalDesignation?: string;
  providerLocations: ProviderLocation[];
  imageLocation?: string;
  setNavigateToDirections: (a: boolean) => void;
  virtualCareOffered?: boolean;
  coverageType?: string;
  dependentSeqNbr?: string;
  nameAttrs?: any;
  surveyCount?: number;
  handleTabChange: (a: number) => void;
  sectionTypeValue: string;
  isTieredDataCard?: boolean;
  isLimitedAccessToProvider?: boolean;
  hospitalBasedProviderIndicator?: boolean;
};

export const DetailsPageHeader = ({
  avatar,
  // NOTE: inNetwork Prop get parsed from RAW GPL responses (INN or OON) in the parent componet in this Fn -> getNetworkStatus() we can simplify this later for now I am using this as designed.
  dependentSeqNbr,
  inNetwork,
  rating,
  npi = '',
  practiceId = '',
  providerId,
  providerName,
  providerType,
  primarySpeciality,
  professionalDesignation,
  providerLocations,
  imageLocation,
  setNavigateToDirections,
  virtualCareOffered = false,
  coverageType,
  nameAttrs,
  surveyCount,
  handleTabChange,
  sectionTypeValue,
  isTieredDataCard,
  isLimitedAccessToProvider = false,
  hospitalBasedProviderIndicator = false,
}: Props) => {
  const { t } = useTranslation();
  const featureFlags = useLagoon('feature-flags')();
  const mobileScreen = useMediaQuery(mobileOnly);
  const showDoesAnythingLookWrong = getFeatureFlag(
    featureFlags,
    'DOES_ANYTHING_LOOK_WRONG'
  );
  const [costEnableFlag] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.COST_ENABLED,
  ]);

  const [eligibilityPcPBannerFlag] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_FIND_ELIGIBLE_PCP_BANNER,
  ]);

  const [selectedId] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    ''
  );

  const [, setDirectionsTriggeredFrom] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.DIRECTIONS_TRIGGERED_FROM,
    ''
  );

  const { navigate } = useRouter();

  const { choosePCP = false, setPCPSearchState } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      choosePCP: state.pcpSearchState.choosePCP,
      setPCPSearchState: state.setPCPSearchState,
    }))
  );
  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { chipValue } = chipStore;

  const ddpCodeUser = getLoggedInMember()?.ddpCode;

  const isPreEffective = useCurrentMemberIsPreEffective();

  const [suggestEditDrawer, setSuggestEditDrawer] = useState(false);
  const [breadcrumbSessionStorage, setBreadcrumbSessionStorage] =
    useSessionStorage<BreadcrumbSessionStorage>(
      Constants.STORAGE_KEYS.SESSION.BREADCRUMB_URLS,
      {}
    );
  const suppressRatings = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PATIENT_REVIEWS
  );
  const suppressCostEstimates = suppressCostEstimatesForPolicies(
    coverageType,
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.COST_ESTIMATES,
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.COST_ESTIMATES_EMPIRE
  );
  const suppressFacilityFlags = getSuppressFacilityFlags();

  const lagoonData = useLagoon(Constants.LAGOON_TABLE.UI_MESSAGING)();

  const memberNotification = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.PRE_EFFECTIVE_NOTIFICATION,
  });

  const viewIdCardHeader = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.VIEW_ID_CARD_HEADER,
  });
  const viewIdCardContent = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.VIEW_ID_CARD_CONTENT,
  });
  const viewIdCardButton = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.VIEW_ID_CARD_BUTTON,
  });

  useEffect(() => {
    breadcrumbSessionStorage[ConstantsRoutes.PROVIDER_DETAILS.key] =
      window.location.pathname.replace('/findcare', '') +
      window.location.search;
    setBreadcrumbSessionStorage(breadcrumbSessionStorage);
  }, []);

  const parseBreadcrumbs = (urls) => {
    let breadcrumbs;
    switch (providerType) {
      case ProviderType.ORGANIZATION:
        breadcrumbs = [
          {
            title: t('DETAILS_PAGE.FACILITY_BREADCRUMB'),
            href: '',
          },
        ];
        break;
      case ProviderType.PROVIDER_GROUP:
        breadcrumbs = [
          {
            title: t('DETAILS_PAGE.PROVIDER_GROUP_BREADCRUMB'),
            href: '',
          },
        ];
        break;
      default:
        breadcrumbs = [
          {
            title: t('DETAILS_PAGE.PROVIDER_BREADCRUMB'),
            href: '',
          },
        ];
        break;
    }

    if (urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.key]) {
      const searchParams = new URLSearchParams(
        urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.key].split('?')[1]
      );

      const breadcrumbObject: { [key: string]: string } = {};
      for (const [key, value] of searchParams) {
        breadcrumbObject[key] = value;
      }

      breadcrumbs.unshift({
        title: t(searchResultBreadcrumbTitle(breadcrumbObject.sectionType)),
        href: `${urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.key]}`,
      });
    }
    if (urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key]) {
      breadcrumbs.unshift({
        title: t('BC Results'),
        href: `${urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key]}`,
      });
    }
    return breadcrumbs;
  };
  const breadcrumbs = parseBreadcrumbs(breadcrumbSessionStorage);
  const [showShareDrawer, setShowShareDrawer] = useState(false);
  const currentMember = getCurrentMember();
  const lineOfBusiness = currentMember?.lineOfBusiness;
  const { data } = usePrimaryCare();
  const { primaryCare } = data;

  const memberPCPIdValue = getCurrentMemberPCPInfo(
    dependentSeqNbr || currentMember?.demographics?.dependentSeqNbr,
    primaryCare
  )?.providerID;

  const providerPCPIdValue = getProviderPCPId(
    providerLocations,
    providerId,
    memberPCPIdValue
  );

  const primaryCarePCPIdValue = getCurrentMemberPCPInfo(
    dependentSeqNbr || currentMember?.demographics?.dependentSeqNbr,
    primaryCare
  )?.pcpID;

  const memberPCPId = getDetailsNormalizedProviderId(
    coverageType,
    currentMember?.lineOfBusiness,
    memberPCPIdValue
  );

  const providerPCPId = isIfpMember(lineOfBusiness)
    ? providerPCPIdValue
    : getDetailsNormalizedProviderId(
        coverageType,
        currentMember?.lineOfBusiness,
        providerPCPIdValue
      );

  const primaryCarePCPId = getDetailsNormalizedProviderId(
    coverageType,
    currentMember?.lineOfBusiness,
    primaryCarePCPIdValue
  );
  const normalizedStoreProviderId = getDetailsNormalizedProviderId(
    coverageType,
    currentMember?.lineOfBusiness,
    providerId
  );

  const currentPcpEndDate = getCurrentMemberPCPInfo(
    dependentSeqNbr || currentMember?.demographics?.dependentSeqNbr,
    primaryCare
  )?.endDate;

  const showWantToChangeYourPCPCard =
    providerPCPId === memberPCPId ||
    normalizedStoreProviderId === primaryCarePCPId;

  const medicalCoverage = getMemberHealthCoverageType(
    currentMember?.eligibility,
    CoverageTypesCodes.MEDICAL
  );

  const ChoosePCPButton = () => {
    const handleChoosePCPClick = () => {
      adobeLinkTrackEvent({
        name: 'choose pcp button',
        location: 'body:details header',
        type: 'internal',
      });

      const pcpStoreValues = {
        choosePCPId: providerId,
        selectedProviderType:
          providerType === ProviderType.PROVIDER_GROUP
            ? ProviderType.MEDICAL_GROUP
            : providerType,
      };
      setPCPSearchState(pcpStoreValues);
      const urlParams = convertObjectToUrlParams(chipStore, {
        ...pcpStoreValues,
        coverageType,
      });
      navigate(`/choose-pcp/locations/${urlParams}`);
    };
    const pcpLocation = providerLocations.find(
      (item) => item.acceptingNewPatients && item.hasPCPServiceAvailability
    );
    if (choosePCP && pcpLocation && memberPCPId !== providerPCPId)
      return (
        <ChoosePCPButtonContainer
          before={<IconMaterial color="$white" icon="check" />}
          data-auto-testid="choosePCP-button"
          data-testid="choosePCP-button"
          onClick={handleChoosePCPClick}
          size="$md"
        >
          {t('PRIMARY_CARE_PROVIDER.CHOOSE_PCP')}
        </ChoosePCPButtonContainer>
      );
    return null;
  };

  const displayVirtualCareIcon =
    virtualCareOffered &&
    isVirtualCareIconFeatureEnabledOnDetailsHeader(featureFlags, coverageType);

  const directionsOnClick = () => {
    if (setNavigateToDirections) {
      setDirectionsTriggeredFrom(
        Constants.DETAILS_PAGE_HEADER_DIRECTIONS_BUTTON
      );
      setNavigateToDirections(true);
    }
  };

  const handleChipsOnClick = (chipName) => {
    if (chipName === Constants.DETAILS_PAGE_HEADER.TABS.DIRECTIONS) {
      directionsOnClick();
    }

    adobeLinkTrackEvent({
      name: chipName,
      location: 'body:details header',
      type: 'internal',
    });
  };
  useEffect(() => {
    if (providerName && providerType) {
      document.title = `${parseProviderName(
        providerName,
        providerType,
        professionalDesignation,
        nameAttrs
      )}  | ${Constants.SITE_NAME}`;
    }
  }, [providerName, providerType, professionalDesignation]);

  if (!providerName || !providerType) {
    return null;
  }

  const showTierProviderTag = getFeatureFlag(
    featureFlags,
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[
      Constants.CHIPS_CATEGORIES.PRIMARY_CARE
    ].TIER_ONE
  );

  const showTier2Badge: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_TIER_TWO_PROVIDER
  );

  const showPreferredFacilityTag = getFeatureFlag(
    featureFlags,
    FeatureFlags.FEATURE_FLAG_KEY_CHIP_MAP[
      Constants.CHIPS_CATEGORIES.FACILITIES
    ].PREFERRED_FACILITY_IDENTIFICATION
  );

  const showGreenDiamondTag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.GREEN_DIAMOND_IDENTIFICATION
  );

  const index = getSelectedLocationIndex(providerLocations, selectedId);
  const selectedLocation = providerLocations?.[index];

  const {
    costIndicator,
    ddpCode: ddpFacilityCode,
    facilityProviderSpecialties,
    isTieredProvider,
    isTier2Provider,
    isGreenDiamondProvider,
    isOxPreferredLab,
  } = selectedLocation;

  const { isPreferredLabNetwork, isFreeStandingFacility } =
    facilityProviderSpecialties || {};

  const { isOXMember } = getIsOxfordAndUSP(
    currentMember,
    coverageType || 'M',
    featureFlags
  );

  const oxfordPlnFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.OXFORD_PLN
  );

  const showPreferredFacilityBadge = isShowPreferredFacilityBadge(
    suppressFacilityFlags,
    showPreferredFacilityTag,
    ddpFacilityCode as FacilityDDPCode,
    ddpCodeUser,
    isPreferredLabNetwork,
    isOxPreferredLab,
    isOXMember,
    isFreeStandingFacility,
    oxfordPlnFlag
  );
  const isTieredPlanEligible = getTierProviderEligibility(
    currentMember,
    isTieredProvider
  );

  const isDisplayTier1Identifier = showTierProviderTagOnHeader(
    showTierProviderTag,
    isTieredPlanEligible
  );

  let benefitsBadgeType;
  if (showGreenDiamondTag && isGreenDiamondProvider) {
    benefitsBadgeType = BenefitsBadgeType.GREEN_DIAMOND;
  } else if (isDisplayTier1Identifier) {
    benefitsBadgeType = BenefitsBadgeType.TIER_1;
  } else if (
    !isDisplayTier1Identifier &&
    showTier2ProviderTagOnHeader(
      showTier2Badge,
      currentMember.isIFPTieredPlan,
      isTier2Provider
    )
  ) {
    benefitsBadgeType = BenefitsBadgeType.TIER_2;
  } else if (
    showPremiumFacilityBadgeOnHeader(
      showTierProviderTag,
      isTieredPlanEligible,
      showPreferredFacilityBadge
    ) &&
    !showTier2ProviderTagOnHeader(
      showTier2Badge,
      currentMember.isIFPTieredPlan,
      isTier2Provider
    )
  ) {
    benefitsBadgeType = BenefitsBadgeType.PREFERRED_FACILITY;
  }

  const formattedEndDate = getFormattedDate(selectedLocation?.network?.end);

  const displayTerminationDateEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISPLAY_FUTURE_TERMINATION_DATE
  );

  const displayFutureTerminationDate = checkTerminationDate(
    displayTerminationDateEnabled,
    formattedEndDate
  );

  const pcpLocation = providerLocations.find(
    (item) => item.acceptingNewPatients && item.hasPCPServiceAvailability
  );

  const lockInIndicator = primaryCare?.[0]?.lockInIndicator;
  const viewIdCardWidth = mobileScreen ? '100%' : '598px';

  const handleViewIdCard = () => {
    window.location.href =
      '/community/dashboard/modal/id-cards?goBackOnClose=1';
  };
  const coverageStartDate = medicalCoverage?.coverageEffDateRange?.startDate;

  const normalizedProviderIdForPCPMenu = getDetailsNormalizedProviderId(
    coverageType,
    currentMember?.lineOfBusiness,
    providerPCPId
  );

  return (
    <HeaderPageDetails>
      <SuggestEditDrawerCard
        avatar={avatar}
        inNetwork={inNetwork}
        providerLocations={providerLocations}
        providerName={providerName}
        providerType={providerType}
        setSuggestEditDrawer={setSuggestEditDrawer}
        suggestEditDrawer={suggestEditDrawer}
      />
      <ShareThisProviderDrawer
        professionalDesignation={professionalDesignation}
        providerLocations={providerLocations}
        providerName={providerName}
        providerType={providerType}
        setShowShareThisProviderDrawer={setShowShareDrawer}
        showShareThisProviderDrawer={showShareDrawer}
        speciality={primarySpeciality}
      />
      {isPreEffective && (
        <Alert
          children={memberNotification?.message}
          css={AlertStyling}
          data-testid="member-notification-alert"
          title={`${memberNotification?.title} ${
            lineOfBusiness === LOB.IFP
              ? dateFormatToDate(coverageStartDate)
              : getFormattedDate(coverageStartDate)
          }`}
          variant="info"
        />
      )}
      <HeaderContainer>
        <HeaderContentWrapper>
          <HeaderFlex alignItems="center" justify="space-between">
            <Breadcrumb
              backgroundColor="$bgProviderDetailsColor"
              breadcrumbs={breadcrumbs}
              onBackButtonClick={() => {
                navigate(-1);
              }}
              showBreadcrumbAtMediumScreenSize={false}
            />
          </HeaderFlex>

          <DetailsHeaderSection>
            <PopoverWrapper>
              {benefitsBadgeType ? (
                <BenefitsBadge badgeType={benefitsBadgeType} />
              ) : null}
            </PopoverWrapper>
          </DetailsHeaderSection>
          <DetailsSection
            costEnableFlag={costEnableFlag}
            costIndicator={costIndicator}
            coverageType={coverageType}
            displayVirtualCareIcon={displayVirtualCareIcon}
            handleTabChange={handleTabChange}
            imageLocation={imageLocation}
            inNetwork={inNetwork}
            nameAttrs={nameAttrs}
            primarySpeciality={primarySpeciality}
            professionalDesignation={professionalDesignation}
            providerName={providerName}
            providerType={providerType}
            rating={rating}
            suppressCostEstimates={suppressCostEstimates}
            suppressRatings={suppressRatings}
            surveyCount={surveyCount}
            virtualCareOffered={virtualCareOffered}
          />
          <ProviderDetailBanner
            displayFutureTerminationDate={displayFutureTerminationDate}
            endDate={currentPcpEndDate}
            locationTotalCount={providerLocations?.length}
            memberPCPId={memberPCPId}
            normalizedProviderID={normalizedProviderIdForPCPMenu}
            normalizedStoreProviderId={normalizedStoreProviderId}
            primaryCarePCPId={primaryCarePCPId}
            providerPCPId={providerPCPId}
            providerType={providerType}
          />
          {showDoesAnythingLookWrong && (
            <ProviderUpdatedSubsection>
              <SuggestEditLink
                data-auto-testid="provider-suggest-edit-link"
                data-testid="provider-suggest-edit-link"
                isStandardAnchor
                onClick={() => setSuggestEditDrawer(true)}
                variant="native"
              >
                {t('DETAILS_PAGE.SUGGEST_EDIT')}
              </SuggestEditLink>
            </ProviderUpdatedSubsection>
          )}
          <InteractSection css={hideScrollbar}>
            <ChoosePCPButton />
            {Constants.DETAILS_CHIPS.map((chip) => (
              <InteractButton
                before={<IconMaterial color="$primary1" icon={chip.icon} />}
                data-auto-testid={`provider-details-chips-${chip.text}`}
                data-testid={`provider-details-chips-${chip.text}`}
                key={`provider-details-chips-${chip.text}`}
                onClick={() => handleChipsOnClick(chip.text)}
                variant="outline"
              >
                {t(chip.text)}
              </InteractButton>
            ))}
            {Constants.DETAILS_CHIPS_NAME.map((chip) => (
              <DetailsPageChips
                category={chipValue}
                chip={chip.text}
                isLimitedAccessToProvider={
                  isLimitedAccessToProvider && hospitalBasedProviderIndicator
                }
                isTieredDataCard={isTieredDataCard}
                key={`details-chip-${chip.text}`}
                locationId={''}
                nameAttrs={nameAttrs}
                npi={npi}
                practiceId={practiceId}
                professionalDesignation={professionalDesignation}
                providerId={providerId}
                providerLocations={providerLocations}
                providerName={providerName}
                providerType={providerType}
                sectionType={sectionTypeValue}
                selectedLocation={selectedLocation}
              />
            ))}
          </InteractSection>
        </HeaderContentWrapper>
      </HeaderContainer>
      <DetailsPagePcpAlerts
        dependentSeqNbr={dependentSeqNbr}
        displayFutureTerminationDate={displayFutureTerminationDate}
        providerId={providerId}
      />
      {choosePCP &&
      !pcpLocation &&
      eligibilityPcPBannerFlag &&
      memberPCPId !== providerPCPId ? (
        <PcPNotChosenBanner selectLocation={providerLocations} />
      ) : null}
      {lockInIndicator && showWantToChangeYourPCPCard ? (
        <ContentWrapper>
          <StyledViewIdBannerBox
            color="$info2"
            data-testid="pcp-view-id-banner-container"
            padding="8px 8px 8px 12px"
            width={viewIdCardWidth}
          >
            <StyledViewIdBannerFlex alignItems="center">
              <IconMaterial icon="info" size={20} />
              <Text fontWeight="$bold" size="$sm">
                {viewIdCardHeader?.message}
              </Text>
              <Text fontWeight="$normal" size="$sm">
                {viewIdCardContent?.message}
              </Text>
              <Button
                data-testid="pcp-view-id-banner-button"
                onClick={handleViewIdCard}
                size={32}
                variant="outline"
              >
                {viewIdCardButton?.message}
              </Button>
            </StyledViewIdBannerFlex>
          </StyledViewIdBannerBox>
        </ContentWrapper>
      ) : null}
    </HeaderPageDetails>
  );
};
