import { styled } from '@abyss/web/tools/styled';

export const TimeStampContentContainer = styled('div', {
  maxWidth: '1200px !important',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  color: '#323333',
  lineHeight: '16px',
  fontSize: '12px !important',
});

export const TimeStampContentHeadingStyle = styled('span', {
  fontWeight: '600',
  marginRight: '4px',
  fontSize: '12px !important',
  lineHeight: '16px',
  color: '#323333',
});

export const TimeStampContentSubTitleStyle = styled('span', {
  fontWeight: '400',
  fontSize: '12px !important',
  lineHeight: '16px',
  color: '#323333',
});

export const TimeStampDate = styled('div', {
  maxWidth: '1200px !important',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  color: '#323333 !important',
  marginRight: '24px',
  fontWeight: '400',
  lineHeight: '16px !important',
  fontSize: '$dateInfoText',
  '@screen < $xl': {
    alignItems: 'flex-start',
  },
  '@screen < $md': {
    display: 'inline-block',
    flexDirection: 'column',
    alignItems: 'flex-start',
    fontSize: '12px',
  },
  '.header': {
    fontWeight: '500',
    marginRight: '4px',
    fontSize: '12px !important',
    lineHeight: '16px',
  },
});
