import { useQuery } from '@abyss/web/hooks/useQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { config } from '@abyss/web/tools/config';
import { useContext } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { errorCodeRouteMap } from './../../common/Constants';
import { convertObjectToUrlParams } from '../../common/Utils';
import { PortalContext } from '../../context/PortalContext';
import { useAnalyticsStore } from '../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../store/useAnalyticsStore/analyticsStore';
import { useErrorStore } from '../../store/useErrorStore';
import { ErrorStore } from '../../store/useErrorStore/errorStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import { getCurrentMember } from '../../utils/user.utils';
import { useTranslationLog } from '../useTranslationLog/useTranslationLog';
import * as LDClient from 'launchdarkly-js-client-sdk';

let ldClient: LDClient.LDClient | null = null;

const initializeLDClient = () => {
  if (!ldClient) {
    const clientSideID = getLaunchDarklyClientID();

    const data = sessionStorage.getItem("psx-lduserkey");

    const cookieMatch = document.cookie.match(/(^|;) ?PS_Widget=([^;]*)(;|$)/);
    const base64String = cookieMatch ? cookieMatch[2] : null;
    const decodedString = base64String ? atob(base64String) : null;
    const parsedData = decodedString ? JSON.parse(decodedString) : null;

    const userkey = data || parsedData?.accountId;

    ldClient = LDClient.initialize(clientSideID, {
      key: userkey,
      kind: "user",
      name: userkey,
    });
  }
};

const getEnv = () => {
  const host = window.location.hostname;
  let env = "uat";
  if (host.includes("int.uhc.com")) {
    env = "int";
  } else if (host.includes("uat.uhc.com")) {
    env = "uat";
  } else if (host.includes(".uhc.com")) {
    env = "prod";
  }
  return env;
}

const getLaunchDarklyClientID = () => {
  const env = getEnv();
  const clientSideID = {
    int: "65a48dce975b3a1017c7be9d",
    uat: "66aed19d287aa51069e08f9d",
    prod: "65a48dc9f393841093a530f6",
  };
  return clientSideID[env];
}

const getCookieValue = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
  return null;
}


const getHeaders = (currentMember, portalData, typeaheadStore) => {
  let headerOptions;

  // Get and set lob and population header
  let lob = typeaheadStore?.lob || currentMember?.lineOfBusiness;
  lob = lob ?? 'UNKNOWN';
  lob = lob.replace(/&/g, 'n').toLowerCase();
  const population = currentMember?.population?.toLowerCase() || lob;
  headerOptions = { lob, population };

  // Get and set portalName header
  if (portalData?.portalName) {
    headerOptions = { ...headerOptions, portal: portalData.portalName };
  }

  //Adding blue/green to query header based on override or LaunchDarkly Flag

  const host = window.location.hostname;

  if(host.includes("int.uhc.com") || host.includes("uat.uhc.com") || host.includes(".uhc.com")) {
    const bgOverrideFlag = getCookieValue("bgTestingEnv");

    if (bgOverrideFlag) {
      headerOptions = {...headerOptions, bgEnv: bgOverrideFlag};
    } else {
      initializeLDClient();

      if (ldClient) {
        ldClient.on('ready', () => {
          const bgFlag = ldClient?.variation('blue-green-live-flag', 'live');
          headerOptions = {...headerOptions, bgEnv: bgFlag};
        });
      }

    }
  }

  return headerOptions;
};

export const useCustomQuery = (
  query,
  queryOptions
): [data: any, getData: (params: any) => any] => {
  const { navigate } = useRouter();
  const { portalData } = useContext(PortalContext);

  const { linkName, originLinkNameForAnalytics } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      linkName: state.analyticsState.linkName,
      originLinkNameForAnalytics:
        state.analyticsState.originLinkNameForAnalytics,
    }))
  );

  const { setErrorStore } = useErrorStore(
    useShallow((state: ErrorStore) => ({
      setErrorStore: state.setErrorStore,
    }))
  );

  const { typeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      typeaheadSearchStore: state.typeaheadSearchStore,
    }))
  );

  const currentMember = getCurrentMember();
  const headerOptions = getHeaders(
    currentMember,
    portalData,
    typeaheadSearchStore
  );

  // In order to get it past LAWW's proxy, the remote calls paths need to follow
  // a format. So we have a different path for LAWW.
  const url =
    portalData?.portalName !== 'LAWW'
      ? queryOptions.url
      : config('GRAPHQL_API_URL_LAWW');

  const translationLogger = useTranslationLog({}, headerOptions, url);

  const [queryResult, queryAction] = useQuery(query, {
    ...{ ...queryOptions, url },
    headers: { ...queryOptions.headers, ...headerOptions },
    onCompleted: (result) => {
      if (queryOptions.onCompleted) {
        queryOptions.onCompleted(result);
      }

      if (result?.data?.missingPSXCodeTranslations?.length) {
        translationLogger({
          variables: {
            missedTranslations: result.data.missingPSXCodeTranslations,
          },
        });
      }
    },
    onError: (error) => {
      const gQLError = error?.response?.data?.errors?.[0]?.extensions;
      const regEx = /4\d{2}|5\d{2}/;
      if (
        regEx.test(gQLError?.status) &&
        gQLError?.pageType !== 'Custom' &&
        gQLError?.pageType !== 'Ignore' &&
        error?.operationName !== 'ProviderSearch'
      ) {
        const errorStoreValues = {
          message: gQLError?.message,
          code: gQLError?.status,
          source: error?.operationName,
          uiSection: linkName || originLinkNameForAnalytics,
        };
        setErrorStore(errorStoreValues);
        // @ts-ignore
        const urlParams = convertObjectToUrlParams(
          {},
          {
            ...errorStoreValues,
          }
        );

        navigate(
          `${
            errorCodeRouteMap[gQLError?.status] || errorCodeRouteMap['4xx']
          }${urlParams}`
        );
      }
      if (queryOptions.onError && typeof queryOptions.onError === 'function') {
        queryOptions.onError(gQLError);
      }
    },
  });
  return [queryResult, queryAction];
};
