import { Constants } from '../common/Constants';

export const isVirtualVisitOnlyAddress = (
  chipValue: string,
  addressLine: string
) => {
  const virtualVisitPrefix: string = Constants.VIRTUAL_VISIT;
  const isSpecialistOrBehavioralHealth: boolean =
    chipValue === Constants.CHIPS_CATEGORIES.MEDICAL_SPECIALISTS ||
    chipValue === Constants.CHIPS_CATEGORIES.BEHAVIORAL_HEALTH;
  return (
    isSpecialistOrBehavioralHealth &&
    addressLine?.toLowerCase().startsWith(virtualVisitPrefix.toLowerCase())
  );
};
