import { TFunction } from 'i18next';
import find from 'lodash/find';

import translationsEnJson from '../../../../../public/locale/en/translation.json';
import {
  Constants,
  CoverageTypesCodes,
  TypeaheadChipCategoriesMapping,
} from '../../../../common/Constants';
import { Option } from '../../../../models/ProviderSearch';
import { TypeaheadSearchState } from '../../../../store/useTypeaheadStore/typeaheadStore';
import { toCapitalCase } from '../../../../utils/string.utils';
import {
  compareAndReturn,
  getAOEOrSpecialityById,
  getAoeCodesForEAP,
  getDntFiltersFromQueryParams,
} from './utils';

export const getCorrectChipCategory = (
  vertical: string,
  typeaheadPreferenceCodes: any[],
  specialityResults: any
) => {
  const sortedRollupCodesOfClickedSuggestion = vertical
    ?.split(',')
    ?.map((i) => i.trim())
    ?.sort((a, b) => a.localeCompare(b))
    ?.join(',');

  const sortedTypeaheadPreferenceCodes = typeaheadPreferenceCodes?.map(
    (codes) => ({
      ...codes,
      rollupCodes: codes?.rollupCodes
        ?.split(',')
        ?.map((i) => i.trim())
        ?.sort()
        ?.join(),
    })
  );

  const foundCategories =
    sortedTypeaheadPreferenceCodes?.filter(
      (item) => item.rollupCodes === sortedRollupCodesOfClickedSuggestion
    ) || [];

  const uniqueChips = new Set();
  foundCategories?.forEach((obj) => {
    uniqueChips.add(obj?.chipCategory);
  });
  const isDuplicateFound = uniqueChips.size > 1;

  const isThisChipEnabled = specialityResults?.some(
    (item) =>
      item.chipEnabled &&
      TypeaheadChipCategoriesMapping[item.chipCategory] ===
        foundCategories[0]?.chipCategory
  );

  if (foundCategories?.length > 0 && !isDuplicateFound && isThisChipEnabled) {
    return foundCategories[0]?.chipCategory;
  }
  return null;
};

export const hasResultsLoadedForParallelCategoryCount =
  (parallelCategoryCountResults: {
    isNameResultsLoaded: boolean;
    isSpecialityResultsLoaded: boolean;
    nameResults: any[];
    specialityResults: any[];
  }) =>
    parallelCategoryCountResults.isNameResultsLoaded &&
    parallelCategoryCountResults.isSpecialityResultsLoaded &&
    (parallelCategoryCountResults.nameResults.length > 0 ||
      parallelCategoryCountResults.specialityResults.length > 0);

export const getSpecialtyCode = (pesKeyword, searchCategory, specialtyCodes) =>
  pesKeyword ?? Array.isArray(searchCategory)
    ? undefined
    : specialtyCodes[searchCategory?.toUpperCase()];

export const getSelectedCoverageType = (value: string) =>
  find(Constants.MOCK_CARE_CATEGORIES, {
    value,
  })?.coverageType || CoverageTypesCodes.MEDICAL;

export const getPesSearchTerm = (
  bhProgramFacilityAgg,
  includeSpecialityRollupCodes,
  search
) =>
  bhProgramFacilityAgg && !includeSpecialityRollupCodes
    ? ''
    : compareAndReturn(includeSpecialityRollupCodes, search);

export const getGenderCode = (code: string): Option => {
  const genderValue: string = toCapitalCase(code);
  return {
    label: genderValue,
    value: genderValue,
    dnt_label: genderValue,
  };
};

export const getVirtualCareCode = (t: TFunction): Option => ({
  label: t('FILTER_OPTIONS.VirtualCare.Y'),
  dnt_label: translationsEnJson.FILTER_OPTIONS.VirtualCare.Y,
  value: true,
});

export const getConditionAoeCodes = (
  condition: string | undefined,
  deepLinkMapping: any,
  aoeTranslations: any
) => {
  if (condition && deepLinkMapping) {
    const conditionIds = getDntFiltersFromQueryParams(
      condition,
      deepLinkMapping
    );
    const areaOfExpertise = getAOEOrSpecialityById(
      conditionIds,
      aoeTranslations
    );
    const conditionAoeCodes = Object.keys(aoeTranslations).filter((key) =>
      conditionIds.includes(aoeTranslations[key])
    );
    return { areaOfExpertise, conditionAoeCodes };
  }
  return { areaOfExpertise: [], conditionAoeCodes: [] };
};

export const getSearchTitle = (
  search: string | null,
  searchType: string,
  userZip: string
) => {
  if (
    searchType === Constants.SEARCH_TYPES.LOCATION &&
    search !== Constants.RESULT_SECTION.PRIMARY_CARE_PROVIDERS
  ) {
    return userZip;
  }
  return search || '';
};

export const setupAoeCodesForEAP = (
  eapSpecialitySearch: boolean,
  eapBHProgramFlag: boolean,
  typeaheadAoeCodes: string[]
) => {
  if (eapSpecialitySearch && !eapBHProgramFlag) {
    const aoeCodes = getAoeCodesForEAP() || [];
    const existingCodes = new Set(typeaheadAoeCodes);
    aoeCodes.forEach((code) => {
      if (!existingCodes.has(code)) {
        typeaheadAoeCodes.push(code);
        existingCodes.add(code);
      }
    });
  }
  return;
};

export const getFilterValue = (
  typeaheadSearchStore: TypeaheadSearchState,
  name: string
) => {
  const filterValueFromStore = typeaheadSearchStore[name];
  if (!filterValueFromStore) {
    return undefined;
  }
  return filterValueFromStore;
};
